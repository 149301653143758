import React, { useState, useEffect } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  Dropdown,
  MessageBox,
  CreateButton,
  ResetButton,
} from '../../../components/FormElements';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getReturnUrl } from '../../../utils';
import { useCallback } from 'react';

const CardsCategoriesMappingCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [banks, setBanks] = useState([]);
  const [providers, setProviders] = useState([]);
  const [cards, setCards] = useState([]);
  const [categories, setCategories] = useState([]);
  const [banksId, setBanksId] = useState('');
  const [providersId, setProvidersId] = useState('');
  const [cardsId, setCardsId] = useState('');
  const [categoriesId, setCategoriesId] = useState('');
  const [mappingStatus, setMappingStatus] = useState('');

  const readBanks = useCallback(() => {
    API.get('/configurations/banks').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setBanks(data);
      }
    });
  }, []);

  const readProviders = useCallback(() => {
    API.get('/configurations/providers').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setProviders(data);
      }
    });
  }, []);

  const readCards = useCallback(() => {
    API.get('/configurations/cards').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setCards(data);
      }
    });
  }, []);

  const readCategories = useCallback(() => {
    API.get('/configurations/categories').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setCategories(data);
      }
    });
  }, []);

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setBanksId('');
    setProvidersId('');
    setCardsId('');
    setCategoriesId('');
    setMappingStatus('');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      banksId: banksId,
      providersId: providersId,
      cardsId: cardsId,
      categoriesId: categoriesId,
      mappingStatus: mappingStatus,
    };
    API.post('/configurations/cards-categories-mapping', formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readBanks();
    readProviders();
    readCards();
    readCategories();
  }, [readBanks, readProviders, readCards, readCategories]);

  const banksOptions = [{ title: '-- SELECT BANK --', value: '' }];
  if (banks.length > 0) {
    banks?.forEach(({ id, bankName }) =>
      banksOptions.push({
        title: bankName,
        value: id,
      })
    );
  }

  const providersOptions = [{ title: '-- SELECT PROVIDER --', value: '' }];
  if (providers.length > 0) {
    providers.forEach(({ id, providerName }) =>
      providersOptions.push({
        title: providerName,
        value: id,
      })
    );
  }

  const cardsOptions = [{ title: '-- SELECT CARD --', value: '' }];
  if (cards.length > 0) {
    cards
      ?.filter((item) => (banksId !== '' ? item.banksId === banksId : true))
      ?.filter((item) =>
        providersId !== '' ? item.providersId === providersId : true
      )
      ?.forEach(({ id, cardTitle }) =>
        cardsOptions.push({
          title: cardTitle,
          value: id,
        })
      );
  }

  const categoriesOptions = [{ title: '-- SELECT CATEGORY --', value: '' }];
  if (categories.length > 0) {
    categories?.forEach(({ id, category }) =>
      categoriesOptions.push({
        title: category,
        value: id,
      })
    );
  }

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Bank</Label>
                <Dropdown
                  placeholder='Please select bank'
                  value={banksId}
                  onChange={(value) => {
                    setBanksId(value);
                    if (banksId !== value) {
                      setProvidersId('');
                      setCardsId('');
                    }
                  }}
                  options={banksOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Provider</Label>
                <Dropdown
                  placeholder='Please select provider'
                  value={providersId}
                  onChange={(value) => {
                    setProvidersId(value);
                    if (providersId !== value) {
                      setCardsId('');
                    }
                  }}
                  options={providersOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Card</Label>
                <Dropdown
                  placeholder='Please select card'
                  value={cardsId}
                  onChange={(value) => {
                    setCardsId(value);
                  }}
                  options={cardsOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <Fieldset>
              <Label required>Category</Label>
              <Dropdown
                placeholder='Please select category'
                value={categoriesId}
                onChange={setCategoriesId}
                options={categoriesOptions}
                disabled={isLoading}
              />
            </Fieldset>
            <Fieldset>
              <Label required>Mapping Status</Label>
              <Dropdown
                placeholder='Please select mapping status'
                value={mappingStatus}
                onChange={setMappingStatus}
                options={[
                  {
                    title: '-- SELECT MAPPING STATUS --',
                    value: '',
                  },
                  {
                    title: 'Active',
                    value: 'Active',
                  },
                  {
                    title: 'Inactive',
                    value: 'Inactive',
                  },
                ]}
                disabled={isLoading}
              />
            </Fieldset>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading} type='submit'>
              Create
            </CreateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(CardsCategoriesMappingCreate);
