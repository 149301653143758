import { MODULES, PERMISSION_TYPES } from './index';

const {
  DASHBOARD,
  ACCOUNTS_MANAGEMENT,
  ROLES_AND_PERMISSIONS,
  ACCOUNTS,
  USERS,
  CONFIGURATIONS_MANAGEMENT,
  BANKS,
  PROVIDERS,
  CARDS,
  SITES,
  CATEGORIES,
  CARDS_CATEGORIES_MAPPING,
  SITES_CATEGORIES_MAPPING,
  COMMON_APIS,
  OFFERS_MANAGEMENT,
  OFFERS,
} = MODULES;

const {
  CREATE,
  READ,
  UPDATE,
  DELETE,
  CHANGE_PASSWORD,
  READ_LATEST_OTP,
  BROWSER_HISTORY,
} = PERMISSION_TYPES;

const modulesMapper = [
  {
    moduleName: DASHBOARD,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: ROLES_AND_PERMISSIONS,
    masterModule: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: ACCOUNTS,
    masterModule: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
      {
        permission: CHANGE_PASSWORD,
        dependencyPermissions: [READ],
      },
      {
        permission: READ_LATEST_OTP,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: USERS,
    masterModule: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
      {
        permission: BROWSER_HISTORY,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: BANKS,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: PROVIDERS,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: CARDS,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: SITES,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: CATEGORIES,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: CARDS_CATEGORIES_MAPPING,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: SITES_CATEGORIES_MAPPING,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: COMMON_APIS,
    masterModule: CONFIGURATIONS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: OFFERS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: OFFERS,
    masterModule: OFFERS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
];

export default modulesMapper;
