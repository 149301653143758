import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  width: 100%;
  height: 100%;
`;

const StyledFooterInner = styled.section`
  width: 95%;
  max-width: 1800px;
  font-size: 13px;
  font-weight: normal;
  margin: 0 auto;
  padding: 20px 0px;
  p {
    padding: 0px;
    color: #000000;
    text-align: center;
  }
  ul {
    margin: 0px 0px;
    padding: 0px;
    li {
      display: inline-block;
      margin: 0px 5px;
      a {
        color: #0095ff;
        text-decoration: none;
        display: inline-block;
        margin: 10px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  @media (max-width: 680px) {
    display: block;
    text-align: center;
    padding: 20px 0px;
  }
`;

const Footer = ({ appName }) => {
  return (
    <StyledFooter>
      <StyledFooterInner>
        <p>
          &copy; Copyrights {appName} {new Date().getFullYear()}. All Rights
          Reserved.
        </p>
      </StyledFooterInner>
    </StyledFooter>
  );
};

export default Footer;
