import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAsterisk,
  faEdit,
  faHistory,
  faKey,
} from '@fortawesome/free-solid-svg-icons';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  PasswordInput,
  TextInput,
  CreateButton,
  ResetButton,
  CustomConfirmButton,
  MessageBox,
  Switch,
  CustomButton,
  Button,
} from '../../../components/FormElements';
import { Image } from '../../../components/Image';
import { TableBuilder } from '../../../components/TableElements';
import Modal from '../../../components/Modal';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import {
  StyledLink,
  StyledModalContainer,
  StyledFiltersContainer,
  StyledOTPModal,
  StyledOTPText,
} from '../../../components/Styled';
import Pagination from '../../../components/Pagination/Pagination';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
} from '../../../utils';
import FiltersButton from '../../../components/FiltersButton/FiltersButton';
import { isEmpty } from 'lodash';

const UsersTable = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 30,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [usersId, setUsersId] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [usersLatestOtp, setUsersLatestOtp] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setNewPassword('');
    setConfirmNewPassword('');
  };

  const resetFilters = () => {
    setSearchQuery('');
    readData();
  };

  const readData = useCallback(
    (searchQuery = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      setUsers([]);
      API.get(
        `/accounts-management/users/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&searchQuery=${searchQuery}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
              redirectToPageOne();
            } else {
              setTotalRecords(pageInfo.totalRecords);
              setUsers(data);
            }
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(searchQuery);
  };

  const readUsersLatestOTP = (usersId) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/accounts-management/users/last-otp/${usersId}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setUsersLatestOtp(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateData = (value, data) => {
    const newData = { ...data };
    newData.photo = '';
    newData.usersStatus = value === true ? `Active` : `Inactive`;
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put(
      `/accounts-management/users/${newData.id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&searchQuery=${searchQuery}`,
      newData
    )
      .then((response) => {
        const { status, message, data } = response.data;

        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            setUsers(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteData = (id) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.delete(
      `/accounts-management/users/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&searchQuery=${searchQuery}`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            setUsers(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put(`/accounts-management/users/change-password/${usersId}`, {
      newPassword,
      confirmNewPassword,
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            setUsersId('');
            setModalType('');
            setShowModal(false);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(searchQuery);
    }
  }, [paginationData, readData]);

  const { accountsPermissions, subRoutes } = props;
  let updateLink = '';
  let updateTitle = '';
  let browserHistoryLink = '';
  let browserHistoryTitle = '';
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canDelete =
    accountsPermissions.includes(PERMISSION_TYPES.DELETE) || false;
  const canChangePassword =
    accountsPermissions.includes(PERMISSION_TYPES.CHANGE_PASSWORD) || false;
  const canReadLatestOTP =
    accountsPermissions.includes(PERMISSION_TYPES.READ_LATEST_OTP) || false;
  const canReadBrowserHistory =
    accountsPermissions.includes(PERMISSION_TYPES.BROWSER_HISTORY) || false;
  if (canUpdate) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) => permissionRequired === PERMISSION_TYPES.UPDATE
    );
    updateLink = path;
    updateTitle = title;
  }
  if (canReadBrowserHistory) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) =>
        permissionRequired === PERMISSION_TYPES.BROWSER_HISTORY
    );
    browserHistoryLink = path;
    browserHistoryTitle = title;
  }

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method='POST' action='#' onSubmit={filterData}>
          <Fieldset>
            <Label>Search</Label>
            <TextInput
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder='First Name, Last Name, Email'
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type='submit'>
              Filter
            </Button>
            <ResetButton
              disabled={isLoading}
              type='button'
              onClick={resetFilters}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === '' &&
          !showModal &&
          users?.length === 0 &&
          isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          users?.length === 0
        }
        message={`No users found`}
      />
      {!showModal && responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {users?.length > 0 && (
        <>
          <TableBuilder
            isLoading={!showModal && users?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: '',
                dataSelector: 'id',
                align: 'center',
                sticky: true,
                canSort: false,
                width: '100px',
                CellRenderer: (value, item) => (
                  <>
                    {canUpdate && (
                      <StyledLink
                        to={updateLink.replace(':id', value)}
                        title={updateTitle}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledLink>
                    )}
                    {canDelete && (
                      <CustomConfirmButton onClick={() => deleteData(value)} />
                    )}
                    {canChangePassword && (
                      <CustomButton
                        icon={faKey}
                        onClick={() => {
                          setUsersId(value);
                          setModalType(PERMISSION_TYPES.CHANGE_PASSWORD);
                          setShowModal(true);
                        }}
                        style={{ color: 'orange' }}
                      />
                    )}
                    {canReadLatestOTP && (
                      <CustomButton
                        icon={faAsterisk}
                        onClick={() => {
                          readUsersLatestOTP(value);
                          setModalType(PERMISSION_TYPES.READ_LATEST_OTP);
                          setShowModal(true);
                        }}
                        style={{ color: 'gray' }}
                      />
                    )}
                    {canReadBrowserHistory && (
                      <StyledLink
                        to={browserHistoryLink.replace(':id', value)}
                        title={browserHistoryTitle}
                      >
                        <FontAwesomeIcon icon={faHistory} />
                      </StyledLink>
                    )}
                  </>
                ),
              },
              {
                title: 'Photo',
                dataSelector: 'photo',
                dataType: 'string',
                canSort: false,
                CellRenderer: (value, item) => (
                  <Image
                    source={value ? value : '/no-image.png'}
                    alt={item.firstName}
                    style={{ width: '50px', height: '50px' }}
                  />
                ),
              },
              {
                title: 'Full Name',
                dataSelector: 'firstName',
                dataType: 'string',
                CellRenderer: (value, item) => {
                  const { lastName } = item;
                  return (
                    <>
                      {value} {lastName}
                    </>
                  );
                },
              },
              {
                title: 'Email',
                dataSelector: 'emailAddress',
                dataType: 'string',
                CellRenderer: (value) => (!isEmpty(value) ? value : 'N/A'),
              },
              {
                title: 'Mobile',
                dataSelector: 'mobileNumber',
                dataType: 'number',
                CellRenderer: (value) => (!isEmpty(value) ? value : 'N/A'),
              },
              {
                title: 'Gender',
                dataSelector: 'gender',
                dataType: 'string',
                CellRenderer: (value) => (!isEmpty(value) ? value : 'N/A'),
              },
              {
                title: 'Date of Birth',
                dataSelector: 'dateOfBirth',
                dataType: 'string',
                CellRenderer: (value) => (!isEmpty(value) ? value : 'N/A'),
              },
              {
                title: 'Created On',
                dataSelector: 'createdOn',
                dataType: 'string',
                CellRenderer: (value) => (!isEmpty(value) ? value : 'N/A'),
              },
              {
                title: 'Status',
                dataSelector: 'usersStatus',
                dataType: 'string',
                align: 'center',
                canSort: false,
                CellRenderer: (value, data) => {
                  return (
                    <Switch
                      value={value === 'Active'}
                      onChange={(value) => updateData(value, data)}
                    />
                  );
                },
              },
            ]}
            tableData={users}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
          <Modal
            modalStatus={showModal}
            setModalStatus={() => {
              resetResponseData();
              resetForm();
              setModalType('');
              setShowModal(false);
            }}
            hideCloseButton
          >
            <StyledModalContainer width={'500px'}>
              <h3>
                {modalType === PERMISSION_TYPES.CHANGE_PASSWORD
                  ? 'Change Password'
                  : modalType === PERMISSION_TYPES.READ_LATEST_OTP
                  ? 'Users Latest OTP'
                  : ''}
              </h3>
              <FormContainer>
                {modalType === PERMISSION_TYPES.READ_LATEST_OTP ? (
                  <StyledOTPModal>
                    {isLoading ? (
                      <StyledOTPText>
                        Please wait, we are feting the data
                      </StyledOTPText>
                    ) : usersLatestOtp?.length > 0 ? (
                      <StyledOTPText>OTP is {usersLatestOtp}</StyledOTPText>
                    ) : (
                      <StyledOTPText>No OTP Found</StyledOTPText>
                    )}
                    <OverlayLoader showLoader={showModal && isLoading} />
                  </StyledOTPModal>
                ) : (
                  <Form method='POST' action='#' onSubmit={onSubmit}>
                    {modalType === PERMISSION_TYPES.CHANGE_PASSWORD && (
                      <>
                        <Fieldset>
                          <Label required>New Password</Label>
                          <PasswordInput
                            placeholder='Please enter your new password'
                            value={newPassword}
                            onChange={setNewPassword}
                            autoComplete='off'
                            disabled={isLoading}
                          />
                        </Fieldset>
                        <Fieldset>
                          <Label required>Confirm New Password</Label>
                          <PasswordInput
                            placeholder='Please re-enter your new password'
                            value={confirmNewPassword}
                            onChange={setConfirmNewPassword}
                            autoComplete='off'
                            disabled={isLoading}
                          />
                        </Fieldset>
                      </>
                    )}
                    {showModal && responseStatus !== '' && (
                      <Fieldset>
                        <MessageBox
                          status={responseStatus}
                          message={responseMessage}
                        />
                      </Fieldset>
                    )}
                    <Fieldset>
                      <CreateButton
                        disabled={isLoading}
                        type='submit'
                        style={{ width: 'auto' }}
                      >
                        {modalType === PERMISSION_TYPES.CHANGE_PASSWORD
                          ? `Change Password`
                          : `Submit`}
                      </CreateButton>
                      <ResetButton
                        disabled={isLoading}
                        type='button'
                        onClick={resetForm}
                      >
                        Reset
                      </ResetButton>
                    </Fieldset>
                    <OverlayLoader showLoader={showModal && isLoading} />
                  </Form>
                )}
              </FormContainer>
            </StyledModalContainer>
          </Modal>
        </>
      )}
    </>
  );
};

export default withAppHOC(UsersTable);
