import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import {
  CustomConfirmButton,
  MessageBox,
  Form,
  Fieldset,
  Button,
  ResetButton,
  Dropdown,
  Label,
  TextInput,
} from '../../../components/FormElements';
import { TableBuilder } from '../../../components/TableElements';
import {
  StyledLink,
  StyledIconTitleContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledFiltersContainer,
  StyledSpan,
} from '../../../components/Styled';
import { Image } from '../../../components/Image';
import Pagination from '../../../components/Pagination/Pagination';
import FiltersButton from '../../../components/FiltersButton/FiltersButton';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
} from '../../../utils';

const OffersTable = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 30,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [sites, setSites] = useState([]);
  const [sitesId, setSitesId] = useState('');
  const [banks, setBanks] = useState([]);
  const [banksId, setBanksId] = useState('');
  const [providers, setProviders] = useState([]);
  const [providersId, setProvidersId] = useState('');
  const [cards, setCards] = useState([]);
  const [cardsId, setCardsId] = useState('');
  const [categories, setCategories] = useState([]);
  const [categoriesId, setCategoriesId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [offers, setOffers] = useState([]);

  const resetForm = () => {
    setSitesId('');
    setBanksId('');
    setProvidersId('');
    setCardsId('');
    setCategoriesId('');
    setStartDate('');
    setEndDate('');
    readData();
  };

  const readSites = useCallback(() => {
    API.get('/configurations/sites').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setSites(data);
      }
    });
  }, []);

  const readBanks = useCallback(() => {
    API.get('/configurations/banks').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setBanks(data);
      }
    });
  }, []);

  const readProviders = useCallback(() => {
    API.get('/configurations/providers').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setProviders(data);
      }
    });
  }, []);

  const readCards = useCallback(() => {
    API.get('/configurations/cards').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setCards(data);
      }
    });
  }, []);

  const readCategories = useCallback(() => {
    API.get('/configurations/categories').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setCategories(data);
      }
    });
  }, []);

  const readData = useCallback(
    (
      sitesId = '',
      banksId = '',
      providersId = '',
      cardsId = '',
      categoriesId = '',
      startDate = '',
      endDate = ''
    ) => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      API.get(
        `/offers?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fSitesId=${sitesId}&fCardsId=${cardsId}&fBanksId=${banksId}&fProvidersId=${providersId}&fCategoriesId=${categoriesId}&fStartDate=${startDate}&fEndDate=${endDate}`
      )
        .then((response) => {
          const { status, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.SUCCESS) {
              setTotalRecords(pageInfo.totalRecords);
              setOffers(data);
            }
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(
      sitesId,
      banksId,
      providersId,
      cardsId,
      categoriesId,
      startDate,
      endDate
    );
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  const deleteData = (id) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.delete(
      `/offers/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fSitesId=${sitesId}&fCardsId=${cardsId}&fBanksId=${banksId}&fProvidersId=${providersId}&fCategoriesId=${categoriesId}&fStartDate=${startDate}&fEndDate=${endDate}`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            setOffers(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!isFiltering) {
      readData(
        sitesId,
        banksId,
        providersId,
        cardsId,
        categoriesId,
        startDate,
        endDate
      );
    }
  }, [paginationData, readData]);

  useEffect(() => {
    readSites();
    readBanks();
    readProviders();
    readCards();
    readCategories();
  }, [readSites, readBanks, readProviders, readCards, readCategories]);

  const sitesOptions = [{ title: '-- SELECT SITE --', value: '' }];
  if (sites.length > 0) {
    sites?.forEach(({ id, siteTitle }) =>
      sitesOptions.push({
        title: siteTitle,
        value: id,
      })
    );
  }

  const banksOptions = [{ title: '-- SELECT BANK --', value: '' }];
  if (banks.length > 0) {
    banks?.forEach(({ id, bankName }) =>
      banksOptions.push({
        title: bankName,
        value: id,
      })
    );
  }

  const providersOptions = [{ title: '-- SELECT PROVIDER --', value: '' }];
  if (providers.length > 0) {
    providers?.forEach(({ id, providerName }) =>
      providersOptions.push({
        title: providerName,
        value: id,
      })
    );
  }

  const cardsOptions = [{ title: '-- SELECT CARD --', value: '' }];
  if (cards.length > 0) {
    cards?.forEach(({ id, cardTitle }) =>
      cardsOptions.push({
        title: cardTitle,
        value: id,
      })
    );
  }

  const categoriesOptions = [{ title: '-- SELECT CATEGORY --', value: '' }];
  if (categories.length > 0) {
    categories?.forEach(({ id, category }) =>
      categoriesOptions.push({
        title: category,
        value: id,
      })
    );
  }

  const { accountsPermissions, subRoutes } = props;
  let updateLink = '';
  let updateTitle = '';
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canDelete =
    accountsPermissions.includes(PERMISSION_TYPES.DELETE) || false;
  if (canUpdate) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) => permissionRequired === PERMISSION_TYPES.UPDATE
    );
    updateLink = path;
    updateTitle = title;
  }

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method='POST' action='#' onSubmit={filterData}>
          <Fieldset>
            <Label>Site</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='Site'
              value={sitesId}
              onChange={setSitesId}
              options={sitesOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Bank</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='Bank'
              value={banksId}
              onChange={setBanksId}
              options={banksOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Provider</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='Provider'
              value={providersId}
              onChange={setProvidersId}
              options={providersOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Card</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='Card'
              value={cardsId}
              onChange={setCardsId}
              options={cardsOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Category</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='Category'
              value={categoriesId}
              onChange={setCategoriesId}
              options={categoriesOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Start Date</Label>
            <TextInput
              type='date'
              value={startDate}
              onChange={setStartDate}
              placeholder='Start Date'
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>End Date</Label>
            <TextInput
              type='date'
              value={endDate}
              onChange={setEndDate}
              placeholder='End Date'
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type='submit'>
              Filter
            </Button>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={responseStatus === '' && offers?.length === 0 && isLoading}
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          offers?.length === 0
        }
        message={`No offers found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {offers?.length > 0 && (
        <>
          <TableBuilder
            isLoading={offers?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: '',
                dataSelector: 'id',
                sticky: true,
                canSort: false,
                width: '100px',
                CellRenderer: (value) => (
                  <>
                    {canUpdate && (
                      <StyledLink
                        to={updateLink.replace(':id', value)}
                        title={updateTitle}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledLink>
                    )}
                    {canDelete && (
                      <CustomConfirmButton onClick={() => deleteData(value)} />
                    )}
                  </>
                ),
              },
              {
                title: 'Site',
                dataSelector: 'siteTitle',
                dataType: 'string',
                canSort: false,
                CellRenderer: (value, item) => (
                  <StyledIconTitleContainer>
                    <StyledIconContainer>
                      <Image
                        source={item.favicon ? item.favicon : '/no-image.png'}
                        alt={value}
                        style={{ width: '50px', height: '50px' }}
                      />
                    </StyledIconContainer>
                    <StyledTitleContainer>
                      {value}
                      <StyledSpan>Domain : {item.domain}</StyledSpan>
                      <StyledSpan>Status : {item.siteStatus}</StyledSpan>
                    </StyledTitleContainer>
                  </StyledIconTitleContainer>
                ),
              },
              {
                title: 'Bank',
                dataSelector: 'bankName',
                dataType: 'string',
                canSort: false,
                CellRenderer: (value, item) =>
                  value ? (
                    <StyledIconTitleContainer>
                      <StyledIconContainer>
                        <Image
                          source={
                            item.bankLogo ? item.bankLogo : '/no-image.png'
                          }
                          alt={value}
                          style={{ width: '50px', height: '50px' }}
                        />
                      </StyledIconContainer>
                      <StyledTitleContainer>
                        {value}
                        <StyledSpan>Status : {item.bankStatus}</StyledSpan>
                      </StyledTitleContainer>
                    </StyledIconTitleContainer>
                  ) : (
                    'N/A'
                  ),
              },
              {
                title: 'Provider',
                dataSelector: 'providerName',
                dataType: 'string',
                canSort: false,
                CellRenderer: (value, item) =>
                  value ? (
                    <StyledIconTitleContainer>
                      <StyledIconContainer>
                        <Image
                          source={
                            item.providerLogo
                              ? item.providerLogo
                              : '/no-image.png'
                          }
                          alt={value}
                          style={{ width: '50px', height: '50px' }}
                        />
                      </StyledIconContainer>
                      <StyledTitleContainer>
                        {value}
                        <StyledSpan>Status : {item.providerStatus}</StyledSpan>
                      </StyledTitleContainer>
                    </StyledIconTitleContainer>
                  ) : (
                    'N/A'
                  ),
              },
              {
                title: 'Card',
                dataSelector: 'cardTitle',
                dataType: 'string',
                CellRenderer: (value, item) =>
                  value ? (
                    <>
                      {value}
                      <StyledSpan>Status : {item.providerStatus}</StyledSpan>
                    </>
                  ) : (
                    'N/A'
                  ),
              },
              {
                title: 'Category',
                dataSelector: 'category',
                dataType: 'string',
                CellRenderer: (value) => (value ? value : 'N/A'),
              },
              {
                title: 'Discount Type',
                dataSelector: 'discountType',
                dataType: 'string',
              },
              {
                title: 'Discount Value',
                dataSelector: 'discountValue',
                dataType: 'string',
              },
              {
                title: 'Start Date',
                dataSelector: 'startDate',
                dataType: 'string',
              },
              {
                title: 'End Date',
                dataSelector: 'endDate',
                dataType: 'string',
              },
            ]}
            tableData={offers}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(OffersTable);
