import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import {
  Switch,
  CustomConfirmButton,
  MessageBox,
  Form,
  Fieldset,
  Button,
  ResetButton,
  Dropdown,
  Label,
  TextInput,
} from '../../../components/FormElements';
import { Image } from '../../../components/Image';
import { TableBuilder } from '../../../components/TableElements';
import {
  StyledLink,
  StyledIconTitleContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledFiltersContainer,
} from '../../../components/Styled';
import Pagination from '../../../components/Pagination/Pagination';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
} from '../../../utils';
import FiltersButton from '../../../components/FiltersButton/FiltersButton';

const CardsTable = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 30,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [cards, setCards] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [banks, setBanks] = useState([]);
  const [banksId, setBanksId] = useState('');
  const [providers, setProviders] = useState([]);
  const [providersId, setProvidersId] = useState('');

  const resetForm = () => {
    setSearchQuery('');
    setBanksId('');
    setProvidersId('');
    readData();
  };

  const readBanks = () => {
    API.get('/configurations/banks').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setBanks(data);
      }
    });
  };

  const readProviders = () => {
    API.get('/configurations/providers').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setProviders(data);
      }
    });
  };

  const readData = useCallback(
    (searchQuery = '', banksId = '', providersId = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      setCards([]);
      API.get(
        `/configurations/cards/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fBanksId=${banksId}&fProvidersId=${providersId}&searchQuery=${searchQuery}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
              redirectToPageOne();
            } else {
              setTotalRecords(pageInfo.totalRecords);
              setCards(data);
            }
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(searchQuery, banksId, providersId);
  };

  const updateData = (value, data) => {
    const newData = { ...data };
    newData.cardStatus = value === true ? `Active` : `Inactive`;
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put(
      `/configurations/cards/${newData.id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fBanksId=${banksId}&fProvidersId=${providersId}&searchQuery=${searchQuery}`,
      newData
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            setCards(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteData = (id) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.delete(
      `/configurations/cards/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fBanksId=${banksId}&fProvidersId=${providersId}&searchQuery=${searchQuery}`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            setCards(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    readBanks();
    readProviders();
  }, []);

  useEffect(() => {
    if (!isFiltering) {
      readData(searchQuery, banksId, providersId);
    }
  }, [paginationData, readData]);

  const { accountsPermissions, subRoutes } = props;
  let updateLink = '';
  let updateTitle = '';
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canDelete =
    accountsPermissions.includes(PERMISSION_TYPES.DELETE) || false;
  if (canUpdate) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) => permissionRequired === PERMISSION_TYPES.UPDATE
    );
    updateLink = path;
    updateTitle = title;
  }

  const banksOptions = [{ title: '-- SELECT BANK --', value: '' }];
  banks.forEach(({ id, bankName }) =>
    banksOptions.push({
      title: bankName,
      value: id,
    })
  );

  const providersOptions = [{ title: '-- SELECT PROVIDER --', value: '' }];
  providers.forEach(({ id, providerName }) =>
    providersOptions.push({
      title: providerName,
      value: id,
    })
  );

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method='POST' action='#' onSubmit={filterData}>
          <Fieldset>
            <Label>Search</Label>
            <TextInput
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder='Search...'
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Bank</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='Banks'
              value={banksId}
              onChange={setBanksId}
              options={banksOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Provider</Label>
            <Dropdown
              style={{ border: '1px solid #cacaca' }}
              placeholder='Providers'
              value={providersId}
              onChange={setProvidersId}
              options={providersOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type='submit'>
              Filter
            </Button>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={responseStatus === '' && cards?.length === 0 && isLoading}
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          cards?.length === 0
        }
        message={`No cards found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {cards?.length > 0 && (
        <>
          <TableBuilder
            isLoading={cards?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: '',
                dataSelector: 'id',
                sticky: true,
                canSort: false,
                width: '100px',
                CellRenderer: (value) => (
                  <>
                    {canUpdate && (
                      <StyledLink
                        to={updateLink.replace(':id', value)}
                        title={updateTitle}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledLink>
                    )}
                    {canDelete && (
                      <CustomConfirmButton onClick={() => deleteData(value)} />
                    )}
                  </>
                ),
              },
              {
                title: 'Bank',
                dataSelector: 'bankName',
                dataType: 'string',
                CellRenderer: (value, item) => (
                  <StyledIconTitleContainer>
                    <StyledIconContainer>
                      <Image
                        source={item.bankLogo ? item.bankLogo : '/no-image.png'}
                        alt={value}
                        style={{ width: '50px', height: '50px' }}
                      />
                    </StyledIconContainer>
                    <StyledTitleContainer>{value}</StyledTitleContainer>
                  </StyledIconTitleContainer>
                ),
              },
              {
                title: 'Provider',
                dataSelector: 'providerName',
                dataType: 'string',
                CellRenderer: (value, item) => (
                  <StyledIconTitleContainer>
                    <StyledIconContainer>
                      <Image
                        source={
                          item.providerLogo
                            ? item.providerLogo
                            : '/no-image.png'
                        }
                        alt={value}
                        style={{ width: '50px', height: '50px' }}
                      />
                    </StyledIconContainer>
                    <StyledTitleContainer>{value}</StyledTitleContainer>
                  </StyledIconTitleContainer>
                ),
              },
              {
                title: 'Card Title',
                dataSelector: 'cardTitle',
                dataType: 'string',
                CellRenderer: (value) => (value ? value : 'N/A'),
              },
              {
                title: 'Status',
                dataSelector: 'cardStatus',
                dataType: 'string',
                align: 'center',
                canSort: false,
                CellRenderer: (value, data) => {
                  return (
                    <Switch
                      value={value === 'Active'}
                      onChange={(value) => updateData(value, data)}
                    />
                  );
                },
              },
            ]}
            tableData={cards}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(CardsTable);
