import React, { useState } from "react";
import styled from "styled-components";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  MessageBox,
  CreateButton,
  ResetButton,
} from "../../../components/FormElements";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import { getReturnUrl } from "../../../utils";
import modulesMapper from "../../../constants/modules-mapping";

const StyledModuleContainer = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-bottom: 0px;
  margin: 0px;
  padding: 0px;
  &:last-child {
    border-bottom: 1px solid #e5e5e5;
  }
  @media (max-width: 1200px) {
    padding: 10px;
    display: block;
    width: calc(100% - 22px);
    label {
      margin: 10px 0px;
    }
  }
`;

const StyledModuleNameContainer = styled.section`
  width: auto;
  min-width: 25%;
`;

const StyledModulePermissionsContainer = styled.section`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-left: 1px solid #e5e5e5;
  @media (max-width: 1200px) {
    display: block;
    border-left: 0px;
  }
`;

const StyledModuleCheckBoxContainer = styled.section`
  display: flex;
  margin: 0px;
  padding: 10px;
  @media (max-width: 1200px) {
    display: block;
    border: 0px;
    padding: 0px;
  }
`;

const StyledModuleName = styled.h3`
  font-size: 14px;
  font-weight: normal;
  color: #000000;
  margin: 0px;
  padding: 10px;
  @media (max-width: 1200px) {
    padding: 0px 0px 10px 0px;
  }
`;

const StyledLabel = styled(Label)`
  display: inline-block;
  margin: 0px;
  padding: 0px;
`;

const RolesAndPermissionsCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [roleName, setRoleName] = useState("");
  const [permissions, setPermissions] = useState({});

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    setRoleName("");
    setPermissions({});
  };

  const onClickCheckBox = (module, permission) => {
    const { moduleName } = module;
    let newPermissions = { ...permissions };

    if (newPermissions[moduleName]) {
      let newModulePermissions = [...newPermissions[moduleName]];
      if (newModulePermissions.includes(permission)) {
        newModulePermissions = newModulePermissions.filter(
          (item) => item !== permission
        );
        newPermissions = {
          ...newPermissions,
          [moduleName]: [...newModulePermissions],
        };
      } else {
        newModulePermissions.push(permission);
        newPermissions = {
          ...newPermissions,
          [moduleName]: [...newModulePermissions],
        };
      }
    } else {
      newPermissions = { ...newPermissions, [moduleName]: [permission] };
    }

    setPermissions(newPermissions);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      roleName,
      permissions: JSON.stringify(permissions),
    };
    API.post("/accounts-management/roles-and-permissions", formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Fieldset>
            <Label required>Role Name</Label>
            <TextInput
              value={roleName}
              onChange={setRoleName}
              placeholder="Please enter role name"
              maxLength={100}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            {modulesMapper &&
              modulesMapper.map((moduleItem) => {
                return (
                  <StyledModuleContainer key={moduleItem.moduleName}>
                    <StyledModuleNameContainer>
                      <StyledModuleName>
                        {moduleItem.moduleName}
                      </StyledModuleName>
                    </StyledModuleNameContainer>
                    <StyledModulePermissionsContainer>
                      {moduleItem.permissions &&
                        moduleItem.permissions.map((permissionItem) => {
                          return (
                            <StyledModuleCheckBoxContainer
                              key={permissionItem.permission}
                            >
                              <input
                                type="checkbox"
                                id={`${moduleItem.moduleName}_${permissionItem.permission}`}
                                name={`${moduleItem.moduleName}_${permissionItem.permission}`}
                                checked={
                                  permissions[moduleItem.moduleName]?.includes(
                                    permissionItem.permission
                                  ) || false
                                }
                                onChange={() =>
                                  onClickCheckBox(
                                    moduleItem,
                                    permissionItem.permission
                                  )
                                }
                                disabled={isLoading}
                              />
                              <StyledLabel
                                htmlFor={`${moduleItem.moduleName}_${permissionItem.permission}`}
                              >
                                {permissionItem.permission}
                              </StyledLabel>
                            </StyledModuleCheckBoxContainer>
                          );
                        })}
                    </StyledModulePermissionsContainer>
                  </StyledModuleContainer>
                );
              })}
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading} type="submit">
              Create
            </CreateButton>
            <ResetButton disabled={isLoading} type="button" onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(RolesAndPermissionsCreate);
