import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDownShortWide,
  faArrowUpShortWide,
} from '@fortawesome/free-solid-svg-icons';
import Table from './Table';
import TableHeader from './TableHeader';
import TableHeading from './TableHeading';
import TableBody from './TableBody';
import TableRow from './TableRow';
import TableData from './TableData';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import { authData } from '../../utils';
import TableFooter from './TableFooter';

const StyledTableOuterContainer = styled.section`
  position: relative;
`;

const StyledTableContainer = styled.section`
  width: auto;
  height: auto;
  margin: 0 auto;
  padding: 0px;
  position: relative;
  overflow: auto;
  white-space: nowrap;
  background-color: #fff;
  border-top-left-radius: ${({ fullScreen }) => (fullScreen ? '0px' : '5px')};
  border-top-right-radius: ${({ fullScreen }) => (fullScreen ? '0px' : '5px')};
  border: 1px solid
    ${({
      theme: {
        colors: { tableHeaderColor },
      },
    }) => (tableHeaderColor ? tableHeaderColor : `#545454`)};
`;

const TableBuilder = ({
  tableHeadings = [],
  tableData = [],
  tableFooter = [],
  isLoading = false,
  showBackgroundColor = false,
  showBackgroundColorSelector = '',
  showBackgroundColors = [],
  hideDataBackgroundColor = false,
  fullScreen = false,
  headingBackgorundColor = '',
  headingTextColor = '',
}) => {
  const [sortList, setSortList] = useState([]);

  const onClickHeader = (dataSelector, dataType) => {
    const newSortList = [...sortList];
    const check = sortList.some((item) => item.dataSelector === dataSelector);
    if (!check) {
      newSortList.push({
        dataSelector,
        dataType,
        sortType: 'ASC',
      });
    } else if (check) {
      const item = sortList.find((item) => item.dataSelector === dataSelector);
      if (item.sortType === 'ASC') {
        newSortList[
          sortList.findIndex((item) => item.dataSelector === dataSelector)
        ].sortType = 'DSC';
      } else if (item.sortType === 'DSC') {
        newSortList.splice(
          sortList.findIndex((item) => item.dataSelector === dataSelector),
          1
        );
      }
    }

    setSortList(newSortList);
  };

  const sortTableData = () => {
    if (sortList.length > 0) {
      const newTableData = [...tableData];
      sortList.forEach(({ dataSelector, dataType, sortType }) => {
        if (dataType === 'string') {
          if (sortType === 'DSC') {
            newTableData.sort((item) => item[dataSelector]).reverse();
          } else {
            newTableData.sort((item) => item[dataSelector]);
          }
        } else if (dataType === 'number') {
          if (sortType === 'DSC') {
            newTableData.sort(
              (item1, item2) =>
                parseFloat(item2[dataSelector]) -
                parseFloat(item1[dataSelector])
            );
          } else {
            newTableData.sort(
              (item1, item2) =>
                parseFloat(item1[dataSelector]) -
                parseFloat(item2[dataSelector])
            );
          }
        } else if (dataType === 'timestamp' || dataType === 'date') {
          if (sortType === 'DSC') {
            newTableData.sort(
              (item1, item2) =>
                new Date(item2[dataSelector]) - new Date(item1[dataSelector])
            );
          } else {
            newTableData.sort(
              (item1, item2) =>
                new Date(item1[dataSelector]) - new Date(item2[dataSelector])
            );
          }
        } else if (dataType === 'boolean') {
          if (sortType === 'DSC') {
            newTableData.sort(
              (item1, item2) =>
                Number(item2[dataSelector]) - Number(item1[dataSelector])
            );
          } else {
            newTableData.sort(
              (item1, item2) =>
                Number(item1[dataSelector]) - Number(item2[dataSelector])
            );
          }
        }
      });
      return newTableData;
    } else {
      return tableData;
    }
  };

  const composeSortIcon = (dataSelector) => {
    let icon = '';

    if (
      sortList.length > 0 &&
      sortList.some((item) => item.dataSelector === dataSelector)
    ) {
      const { sortType } = sortList.find(
        (item) => item.dataSelector === dataSelector
      );
      if (sortType === 'ASC') {
        icon = <FontAwesomeIcon icon={faArrowDownShortWide} />;
      } else if (sortType === 'DSC') {
        icon = <FontAwesomeIcon icon={faArrowUpShortWide} />;
      }
    }

    return icon;
  };

  const finalTableData = sortList.length > 0 ? sortTableData() : tableData;

  const { accountType } = authData.get();

  return (
    <StyledTableOuterContainer>
      <OverlayLoader showLoader={isLoading} />
      <StyledTableContainer fullScreen={fullScreen}>
        <Table>
          {tableHeadings.length > 0 && (
            <TableHeader>
              <TableRow>
                {tableHeadings.map(
                  (
                    {
                      title,
                      dataSelector,
                      dataType,
                      canSort = true,
                      requiredRoles = [],
                      align = 'left',
                      ...rest
                    },
                    index
                  ) =>
                    (requiredRoles.length === 0 ||
                      (requiredRoles.length > 0 &&
                        requiredRoles.includes(accountType))) && (
                      <TableHeading
                        key={`th-${index}`}
                        onClick={() =>
                          canSort && onClickHeader(dataSelector, dataType)
                        }
                        align={align}
                        {...rest}
                        headingBackgorundColor={headingBackgorundColor}
                        headingTextColor={headingTextColor}
                      >
                        {title} {canSort && composeSortIcon(dataSelector)}
                      </TableHeading>
                    )
                )}
              </TableRow>
            </TableHeader>
          )}
          {tableData.length > 0 && (
            <TableBody>
              {finalTableData.map((item, index) => {
                let rowColor = '';
                if (showBackgroundColor) {
                  const value = item[showBackgroundColorSelector];
                  rowColor = showBackgroundColors.find(
                    (item) => item.value === value
                  )?.color;
                }

                return (
                  <TableRow
                    key={`tr-${index}`}
                    style={{
                      backgroundColor: rowColor,
                      borderBottom: hideDataBackgroundColor
                        ? '2px solid #fff'
                        : '',
                    }}
                    hideDataBackgroundColor={hideDataBackgroundColor}
                  >
                    {tableHeadings.map(
                      (
                        {
                          dataSelector,
                          align = 'left',
                          requiredRoles = [],
                          CellRenderer,
                          ...rest
                        },
                        index
                      ) =>
                        (requiredRoles.length === 0 ||
                          (requiredRoles.length > 0 &&
                            requiredRoles.includes(accountType))) && (
                          <TableData
                            align={align}
                            key={`td-${index}`}
                            {...rest}
                            hideDataBackgroundColor={hideDataBackgroundColor}
                          >
                            {CellRenderer
                              ? CellRenderer(item[dataSelector], item)
                              : item[dataSelector]}
                          </TableData>
                        )
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
          {tableFooter.length > 0 && (
            <TableFooter>
              <TableRow key={`tf-123}`}>
                {tableFooter.map(({ align, value }, index) => (
                  <TableData align={align} key={`td-${index}`}>
                    {value}
                  </TableData>
                ))}
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </StyledTableContainer>
    </StyledTableOuterContainer>
  );
};

export default TableBuilder;
