import React, { useState, useEffect } from "react";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  FileInput,
  MessageBox,
  CreateButton,
  ResetButton,
} from "../../../components/FormElements";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import { getBase64, getReturnUrl } from "../../../utils";

const ProvidersCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [providerName, setProviderName] = useState("");
  const [providerLogo, setProviderLogo] = useState([]);
  const [providerLogoBase64, setProviderLogoBase64] = useState("");
  const [providerStatus, setProviderStatus] = useState("");

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    setProviderName("");
    setProviderLogo("");
    setProviderLogoBase64("");
    setProviderStatus("");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      providerName,
      providerLogo: providerLogoBase64,
      providerStatus,
    };
    API.post("/configurations/providers", formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (providerLogo?.length > 0) {
      getBase64(providerLogo[0], setProviderLogoBase64);
    }
  }, [providerLogo]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Fieldset>
            <Label required>Provider Name</Label>
            <TextInput
              value={providerName}
              onChange={setProviderName}
              placeholder="Please enter provider name"
              maxLength={100}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Provider Logo</Label>
            <FileInput
              files={providerLogo}
              onChange={setProviderLogo}
              accept="image/*"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label required>Provider Status</Label>
            <Dropdown
              placeholder="Please select provider status"
              value={providerStatus}
              onChange={setProviderStatus}
              options={[
                {
                  title: "-- SELECT PROVIDER STATUS --",
                  value: "",
                },
                {
                  title: "Active",
                  value: "Active",
                },
                {
                  title: "Inactive",
                  value: "Inactive",
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading} type="submit">
              Create
            </CreateButton>
            <ResetButton disabled={isLoading} type="button" onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(ProvidersCreate);
