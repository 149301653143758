import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import Modal from "../../../components/Modal";
import { Image } from "../../../components/Image";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  MessageBox,
  UpdateButton,
  ResetButton,
  Dropdown,
  FileInput,
} from "../../../components/FormElements";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import { getBase64, getReturnUrl } from "../../../utils";

const StyledViewAttachmentButton = styled.span`
  font-size: 14px;
  width: calc(100% - 22px);
  height: auto;
  border: 1px solid #d0eeff;
  display: block;
  margin: 20px 0px 0px;
  background-color: #edf8ff;
  padding: 15px 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s;
  position: relative;
  &:hover {
    background-color: #d0eeff;
  }
`;

const SitesUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showExistingImage, setShowExistingImage] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [siteTitle, setSiteTitle] = useState("");
  const [domain, setDomain] = useState("");
  const [favicon, setFavicon] = useState([]);
  const [faviconBase64, setFaviconBase64] = useState("");
  const [siteStatus, setSiteStatus] = useState("");
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    const { siteTitle, domain, siteStatus } = originalFormData;
    setSiteTitle(siteTitle);
    setDomain(domain);
    setFavicon([]);
    setFaviconBase64("");
    setSiteStatus(siteStatus);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/configurations/sites/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const { siteTitle, domain, favicon, siteStatus } = data[0];
          setSiteTitle(siteTitle);
          setDomain(domain);
          setFavicon([]);
          setFaviconBase64("");
          setSiteStatus(siteStatus);
          setOriginalFormData({
            siteTitle,
            domain,
            favicon,
            siteStatus,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      siteTitle,
      domain,
      favicon: faviconBase64,
      siteStatus,
    };
    API.put(`/configurations/sites/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readData();
  }, [readData]);

  useEffect(() => {
    if (favicon?.length > 0) {
      getBase64(favicon[0], setFaviconBase64);
    }
  }, [favicon]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Fieldset>
            <Label required>Site Title</Label>
            <TextInput
              value={siteTitle}
              onChange={setSiteTitle}
              placeholder="Please enter site title"
              maxLength={100}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label required>Domain</Label>
            <TextInput
              value={domain}
              onChange={setDomain}
              placeholder="Please enter domain name"
              maxLength={10000}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Site Favicon</Label>
            <FileInput
              files={favicon}
              onChange={setFavicon}
              accept="image/*"
              disabled={isLoading}
            />
            {originalFormData?.favicon && (
              <StyledViewAttachmentButton
                onClick={() => {
                  setShowExistingImage(true);
                  setModalImage(originalFormData.favicon);
                }}
              >
                Click here to view existing file
              </StyledViewAttachmentButton>
            )}
          </Fieldset>
          <Fieldset>
            <Label required>Site Status</Label>
            <Dropdown
              placeholder="Please select site status"
              value={siteStatus}
              onChange={setSiteStatus}
              options={[
                {
                  title: "-- SELECT SITE STATUS --",
                  value: "",
                },
                {
                  title: "Active",
                  value: "Active",
                },
                {
                  title: "Inactive",
                  value: "Inactive",
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type="submit">
              Update
            </UpdateButton>
            <ResetButton disabled={isLoading} type="button" onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
        <Modal
          modalStatus={showExistingImage}
          setModalStatus={setShowExistingImage}
          hideCloseButton
        >
          <Image width={150} source={modalImage} alt={"Existing File"} />
        </Modal>
      </FormContainer>
    </>
  );
};

export default withAppHOC(SitesUpdate);
