import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
} from '../../../components/FormElements';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getReturnUrl } from '../../../utils';

const CardsUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [banks, setBanks] = useState([]);
  const [banksId, setBanksId] = useState('');
  const [providers, setProviders] = useState([]);
  const [providersId, setProvidersId] = useState('');
  const [cardTitle, setCardTitle] = useState('');
  const [cardBackgroundColor, setCardBackgroundColor] = useState('');
  const [promotionLink, setPromotionLink] = useState('');
  const [cardStatus, setCardStatus] = useState('');
  const [offerBackgroundColor, setOfferBackgroundColor] = useState('');
  const [offerTitleColor, setOfferTitleColor] = useState('');
  const [offerTagsColor, setOfferTagsColor] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const {
      banksId,
      providersId,
      cardTitle,
      cardBackgroundColor,
      offerBackgroundColor,
      offerTitleColor,
      offerTagsColor,
      promotionLink,
      cardStatus,
    } = originalFormData;
    setBanksId(banksId);
    setProvidersId(providersId);
    setCardTitle(cardTitle);
    setCardBackgroundColor(cardBackgroundColor);
    setOfferBackgroundColor(offerBackgroundColor);
    setOfferTitleColor(offerTitleColor);
    setOfferTagsColor(offerTagsColor);
    setPromotionLink(promotionLink);
    setCardStatus(cardStatus);
  };

  const readBanks = useCallback(() => {
    API.get('/configurations/banks').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setBanks(data);
      }
    });
  }, []);

  const readProviders = useCallback(() => {
    API.get('/configurations/providers').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setProviders(data);
      }
    });
  }, []);

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/configurations/cards/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            banksId,
            providersId,
            cardTitle,
            cardBackgroundColor,
            offerBackgroundColor,
            offerTitleColor,
            offerTagsColor,
            promotionLink,
            cardStatus,
          } = data[0];
          setBanksId(banksId);
          setProvidersId(providersId);
          setCardTitle(cardTitle);
          setCardBackgroundColor(cardBackgroundColor);
          setOfferBackgroundColor(offerBackgroundColor);
          setOfferTitleColor(offerTitleColor);
          setOfferTagsColor(offerTagsColor);
          setPromotionLink(promotionLink);
          setCardStatus(cardStatus);
          setOriginalFormData({
            banksId,
            providersId,
            cardTitle,
            cardBackgroundColor,
            offerBackgroundColor,
            offerTitleColor,
            offerTagsColor,
            promotionLink,
            cardStatus,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      banksId,
      providersId,
      cardTitle,
      cardBackgroundColor,
      offerBackgroundColor,
      offerTitleColor,
      offerTagsColor,
      promotionLink,
      cardStatus,
    };
    API.put(`/configurations/cards/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readBanks();
    readProviders();
    readData();
  }, [readBanks, readProviders, readData]);

  const banksOptions = [{ title: '-- SELECT BANK --', value: '' }];
  if (banks.length > 0) {
    banks?.forEach(({ id, bankName }) =>
      banksOptions.push({
        title: bankName,
        value: id,
      })
    );
  }

  const providersOptions = [{ title: '-- SELECT PROVIDER --', value: '' }];
  if (providers.length > 0) {
    providers?.forEach(({ id, providerName }) =>
      providersOptions.push({
        title: providerName,
        value: id,
      })
    );
  }

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Bank</Label>
                <Dropdown
                  placeholder='Please select bank'
                  value={banksId}
                  onChange={setBanksId}
                  options={banksOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Provider</Label>
                <Dropdown
                  placeholder='Please select provider'
                  value={providersId}
                  onChange={setProvidersId}
                  options={providersOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Card Title</Label>
                <TextInput
                  value={cardTitle}
                  onChange={setCardTitle}
                  placeholder='Please enter card title'
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Card Background Color</Label>
                <TextInput
                  type='color'
                  value={cardBackgroundColor}
                  onChange={setCardBackgroundColor}
                  placeholder='Please select card background color'
                  disabled={isLoading}
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label>Offer Background Color</Label>
                <TextInput
                  type='color'
                  value={offerBackgroundColor}
                  onChange={setOfferBackgroundColor}
                  placeholder='Please select offers background color'
                  disabled={isLoading}
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Offer Title Color</Label>
                <TextInput
                  type='color'
                  value={offerTitleColor}
                  onChange={setOfferTitleColor}
                  placeholder='Please select offer title color'
                  disabled={isLoading}
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Offer Tags Color</Label>
                <TextInput
                  type='color'
                  value={offerTagsColor}
                  onChange={setOfferTagsColor}
                  placeholder='Please select card background color'
                  disabled={isLoading}
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Promotion Link</Label>
            <TextInput
              value={promotionLink}
              onChange={setPromotionLink}
              placeholder='Please enter promotion link'
              maxLength={1000000000000}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label required>Card Status</Label>
            <Dropdown
              placeholder='Please select card status'
              value={cardStatus}
              onChange={setCardStatus}
              options={[
                {
                  title: '-- SELECT CARD STATUS --',
                  value: '',
                },
                {
                  title: 'Active',
                  value: 'Active',
                },
                {
                  title: 'Inactive',
                  value: 'Inactive',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type='submit'>
              Update
            </UpdateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(CardsUpdate);
