const MODULES = {
  DASHBOARD: 'DASHBOARD',
  ACCOUNTS_MANAGEMENT: 'ACCOUNTS_MANAGEMENT',
  ROLES_AND_PERMISSIONS: 'ROLES_AND_PERMISSIONS',
  ACCOUNTS: 'ACCOUNTS',
  USERS: 'USERS',
  CONFIGURATIONS_MANAGEMENT: 'CONFIGURATIONS_MANAGEMENT',
  BANKS: 'BANKS',
  PROVIDERS: 'PROVIDERS',
  CARDS: 'CARDS',
  SITES: 'SITES',
  CATEGORIES: 'CATEGORIES',
  CARDS_CATEGORIES_MAPPING: 'CARDS_CATEGORIES_MAPPING',
  SITES_CATEGORIES_MAPPING: 'SITES_CATEGORIES_MAPPING',
  COMMON_APIS: 'COMMON_APIS',
  OFFERS_MANAGEMENT: 'OFFERS_MANAGEMENT',
  OFFERS: 'OFFERS',
};

const ROUTE_TYPES = {
  ALLOW_IF_LOGGEDIN: 'ALLOW_IF_LOGGEDIN',
  ALLOW_IF_NOT_LOGGEDIN: 'ALLOW_IF_NOT_LOGGEDIN',
};

const PERMISSION_TYPES = {
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  READ_LATEST_OTP: 'READ_LATEST_OTP',
  BROWSER_HISTORY: 'BROWSER_HISTORY',
};

const VIEW_TYPES = {
  TILES: 'TILES',
  TABLE: 'TABLE',
  LIST: 'LIST',
  GRID: 'GRID',
  VIEW: 'VIEW',
};

const ACCOUNT_TYPES = {
  ADMIN: 'admin',
};

const API_RESPONSE_TYPES = {
  SUCCESS: 'success',
  FAILURE: 'failed',
};

export {
  MODULES,
  ROUTE_TYPES,
  PERMISSION_TYPES,
  VIEW_TYPES,
  ACCOUNT_TYPES,
  API_RESPONSE_TYPES,
};
