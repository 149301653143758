import {
  faUsers,
  faPercent,
  faGears,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import { ROUTE_TYPES, MODULES, PERMISSION_TYPES, VIEW_TYPES } from './index';
import Login from '../containers/Auth/Login';
import ForgotPassword from '../containers/Auth/ForgotPassword';
import Profile from '../containers/Auth/Profile';
import ChangePassword from '../containers/Auth/ChangePassword';
import Sessions from '../containers/Auth/Sessions';
import Logs from '../containers/Auth/Logs';
import Dashboard from '../containers/Dashboard/Dashboard';
import RolesAndPermissionsTable from '../containers/AccountsManagement/RolesAndPermissions/RolesAndPermissionsTable';
import RolesAndPermissionsCreate from '../containers/AccountsManagement/RolesAndPermissions/RolesAndPermissionsCreate';
import RolesAndPermissionsUpdate from '../containers/AccountsManagement/RolesAndPermissions/RolesAndPermissionsUpdate';
import AccountsTable from '../containers/AccountsManagement/Accounts/AccountsTable';
import AccountsCreate from '../containers/AccountsManagement/Accounts/AccountsCreate';
import AccountsUpdate from '../containers/AccountsManagement/Accounts/AccountsUpdate';
import UsersTable from '../containers/AccountsManagement/Users/UsersTable';
import UsersCreate from '../containers/AccountsManagement/Users/UsersCreate';
import UsersBrowserHistory from '../containers/AccountsManagement/Users/UsersBrowserHistory';
import UsersUpdate from '../containers/AccountsManagement/Users/UsersUpdate';
import BanksTable from '../containers/Configurations/Banks/BanksTable';
import BanksCreate from '../containers/Configurations/Banks/BanksCreate';
import BanksUpdate from '../containers/Configurations/Banks/BanksUpdate';
import ProvidersTable from '../containers/Configurations/Providers/ProvidersTable';
import ProvidersCreate from '../containers/Configurations/Providers/ProvidersCreate';
import ProvidersUpdate from '../containers/Configurations/Providers/ProvidersUpdate';
import CardsTable from '../containers/Configurations/Cards/CardsTable';
import CardsCreate from '../containers/Configurations/Cards/CardsCreate';
import CardsUpdate from '../containers/Configurations/Cards/CardsUpdate';
import SitesTable from '../containers/Configurations/Sites/SitesTable';
import SitesCreate from '../containers/Configurations/Sites/SitesCreate';
import SitesUpdate from '../containers/Configurations/Sites/SitesUpdate';
import CategoriesTable from '../containers/Configurations/Categories/CategoriesTable';
import CategoriesCreate from '../containers/Configurations/Categories/CategoriesCreate';
import CategoriesUpdate from '../containers/Configurations/Categories/CategoriesUpdate';
import CardsCategoriesMappingTable from '../containers/Configurations/CardsCategoriesMapping/CardsCategoriesMappingTable';
import CardsCategoriesMappingCreate from '../containers/Configurations/CardsCategoriesMapping/CardsCategoriesMappingCreate';
import CardsCategoriesMappingUpdate from '../containers/Configurations/CardsCategoriesMapping/CardsCategoriesMappingUpdate';
import SitesCategoriesMappingTable from '../containers/Configurations/SitesCategoriesMapping/SitesCategoriesMappingTable';
import SitesCategoriesMappingCreate from '../containers/Configurations/SitesCategoriesMapping/SitesCategoriesMappingCreate';
import SitesCategoriesMappingUpdate from '../containers/Configurations/SitesCategoriesMapping/SitesCategoriesMappingUpdate';
import CommonAPIS from '../containers/Configurations/CommonAPIS/CommonAPISGrid';
import OffersTable from '../containers/OffersManagement/Offers/OffersTable';
import OffersCreate from '../containers/OffersManagement/Offers/OffersCreate';
import OffersUpdate from '../containers/OffersManagement/Offers/OffersUpdate';

const { ALLOW_IF_LOGGEDIN, ALLOW_IF_NOT_LOGGEDIN } = ROUTE_TYPES;
const {
  DASHBOARD,
  ACCOUNTS_MANAGEMENT,
  ROLES_AND_PERMISSIONS,
  ACCOUNTS,
  USERS,
  CONFIGURATIONS_MANAGEMENT,
  BANKS,
  PROVIDERS,
  CARDS,
  SITES,
  CATEGORIES,
  CARDS_CATEGORIES_MAPPING,
  SITES_CATEGORIES_MAPPING,
  COMMON_APIS,
  OFFERS_MANAGEMENT,
  OFFERS,
} = MODULES;
const { CREATE, READ, UPDATE, BROWSER_HISTORY } = PERMISSION_TYPES;
const { TABLE, GRID, VIEW } = VIEW_TYPES;

const ROUTES = [
  {
    path: '/',
    title: 'Home',
    icon: faHome,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: DASHBOARD,
    permissionRequired: READ,
    component: Dashboard,
    hideHeading: true,
    hideBreadcrumbs: true,
    exact: true,
  },
  {
    path: '/login',
    title: 'Login',
    routeType: ALLOW_IF_NOT_LOGGEDIN,
    component: Login,
    hideInNavigation: true,
  },
  {
    path: '/forgot-password',
    title: 'Forgot Password',
    routeType: ALLOW_IF_NOT_LOGGEDIN,
    component: ForgotPassword,
    hideInNavigation: true,
  },
  {
    path: '/profile',
    title: 'My Profile',
    showInHeader: true,
    routeType: ALLOW_IF_LOGGEDIN,
    component: Profile,
    hideInNavigation: true,
  },
  {
    path: '/change-password',
    title: 'Change Password',
    showInHeader: true,
    routeType: ALLOW_IF_LOGGEDIN,
    component: ChangePassword,
    hideInNavigation: true,
  },
  {
    path: '/sessions',
    title: 'My Sessions',
    showInHeader: true,
    routeType: ALLOW_IF_LOGGEDIN,
    component: Sessions,
    hideInNavigation: true,
    viewType: TABLE,
    accountsPermissions: [READ],
  },
  {
    path: '/logs',
    title: 'My Logs',
    showInHeader: true,
    routeType: ALLOW_IF_LOGGEDIN,
    component: Logs,
    hideInNavigation: true,
    viewType: TABLE,
    accountsPermissions: [READ],
  },
  {
    path: '/accounts-management',
    title: 'Accounts Management',
    icon: faUsers,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: ACCOUNTS_MANAGEMENT,
    permissionRequired: READ,
    isDummy: true,
    subRoutes: [
      {
        path: '/accounts-management/roles-and-permissions',
        title: 'Roles & Permissions',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ROLES_AND_PERMISSIONS,
        permissionRequired: READ,
        component: RolesAndPermissionsTable,
        viewType: TABLE,
        masterModule: ACCOUNTS_MANAGEMENT,
        subRoutes: [
          {
            path: '/accounts-management/roles-and-permissions/create',
            title: 'Create Roles & Permissions',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ROLES_AND_PERMISSIONS,
            permissionRequired: CREATE,
            component: RolesAndPermissionsCreate,
            viewType: GRID,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/accounts-management/roles-and-permissions/update/:id',
            title: 'Update Roles & Permissions',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ROLES_AND_PERMISSIONS,
            permissionRequired: UPDATE,
            component: RolesAndPermissionsUpdate,
            viewType: GRID,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/accounts-management/accounts',
        title: 'Accounts',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ACCOUNTS,
        permissionRequired: READ,
        component: AccountsTable,
        viewType: TABLE,
        masterModule: ACCOUNTS_MANAGEMENT,
        subRoutes: [
          {
            path: '/accounts-management/accounts/create',
            title: 'Create Account',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ACCOUNTS,
            permissionRequired: CREATE,
            component: AccountsCreate,
            viewType: GRID,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/accounts-management/accounts/update/:id',
            title: 'Update Account',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ACCOUNTS,
            permissionRequired: UPDATE,
            component: AccountsUpdate,
            viewType: GRID,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/accounts-management/users',
        title: 'Users',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: USERS,
        permissionRequired: READ,
        component: UsersTable,
        viewType: TABLE,
        masterModule: ACCOUNTS_MANAGEMENT,
        subRoutes: [
          {
            path: '/accounts-management/users/create',
            title: 'Create User',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: USERS,
            permissionRequired: CREATE,
            component: UsersCreate,
            viewType: GRID,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/accounts-management/users/browser-history/:id',
            title: 'Users Browser History',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: USERS,
            permissionRequired: BROWSER_HISTORY,
            component: UsersBrowserHistory,
            viewType: VIEW,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/accounts-management/users/update/:id',
            title: 'Update User',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: USERS,
            permissionRequired: UPDATE,
            component: UsersUpdate,
            viewType: GRID,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
    ],
  },
  {
    path: '/configurations',
    title: 'Configurations',
    icon: faGears,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: CONFIGURATIONS_MANAGEMENT,
    permissionRequired: READ,
    isDummy: true,
    subRoutes: [
      {
        path: '/configurations/banks',
        title: 'Banks',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: BANKS,
        permissionRequired: READ,
        component: BanksTable,
        viewType: TABLE,
        masterModule: CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/configurations/banks/create',
            title: 'Create Bank',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: BANKS,
            permissionRequired: CREATE,
            component: BanksCreate,
            viewType: GRID,
            masterModule: CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/configurations/banks/update/:id',
            title: 'Update Bank',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: BANKS,
            permissionRequired: UPDATE,
            component: BanksUpdate,
            viewType: GRID,
            masterModule: CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/configurations/providers',
        title: 'Providers',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: PROVIDERS,
        permissionRequired: READ,
        component: ProvidersTable,
        viewType: TABLE,
        masterModule: CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/configurations/providers/create',
            title: 'Create Provider',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: PROVIDERS,
            permissionRequired: CREATE,
            component: ProvidersCreate,
            viewType: GRID,
            masterModule: CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/configurations/providers/update/:id',
            title: 'Update Provider',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: PROVIDERS,
            permissionRequired: UPDATE,
            component: ProvidersUpdate,
            viewType: GRID,
            masterModule: CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/configurations/cards',
        title: 'Cards',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: CARDS,
        permissionRequired: READ,
        component: CardsTable,
        viewType: TABLE,
        masterModule: CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/configurations/cards/create',
            title: 'Create Card',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: CARDS,
            permissionRequired: CREATE,
            component: CardsCreate,
            viewType: GRID,
            masterModule: CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/configurations/cards/update/:id',
            title: 'Update Card',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: CARDS,
            permissionRequired: UPDATE,
            component: CardsUpdate,
            viewType: GRID,
            masterModule: CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/configurations/sites',
        title: 'Sites',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: SITES,
        permissionRequired: READ,
        component: SitesTable,
        viewType: TABLE,
        masterModule: CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/configurations/sites/create',
            title: 'Create Site',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: SITES,
            permissionRequired: CREATE,
            component: SitesCreate,
            viewType: GRID,
            masterModule: CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/configurations/sites/update/:id',
            title: 'Update Site',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: SITES,
            permissionRequired: UPDATE,
            component: SitesUpdate,
            viewType: GRID,
            masterModule: CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/configurations/categories',
        title: 'Categories',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: CATEGORIES,
        permissionRequired: READ,
        component: CategoriesTable,
        viewType: TABLE,
        masterModule: CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/configurations/categories/create',
            title: 'Create Category',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: CATEGORIES,
            permissionRequired: CREATE,
            component: CategoriesCreate,
            viewType: GRID,
            masterModule: CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/configurations/categories/update/:id',
            title: 'Update Category',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: CATEGORIES,
            permissionRequired: UPDATE,
            component: CategoriesUpdate,
            viewType: GRID,
            masterModule: CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/configurations/cards-categories-mapping',
        title: 'Cards Categories Mapping',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: CARDS_CATEGORIES_MAPPING,
        permissionRequired: READ,
        component: CardsCategoriesMappingTable,
        viewType: TABLE,
        masterModule: CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/configurations/cards-categories-mapping/create',
            title: 'Create Cards Categories Mapping',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: CARDS_CATEGORIES_MAPPING,
            permissionRequired: CREATE,
            component: CardsCategoriesMappingCreate,
            viewType: GRID,
            masterModule: CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/configurations/cards-categories-mapping/update/:id',
            title: 'Update Cards Categories Mapping',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: CARDS_CATEGORIES_MAPPING,
            permissionRequired: UPDATE,
            component: CardsCategoriesMappingUpdate,
            viewType: GRID,
            masterModule: CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/configurations/sites-categories-mapping',
        title: 'Sites Categories Mapping',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: SITES_CATEGORIES_MAPPING,
        permissionRequired: READ,
        component: SitesCategoriesMappingTable,
        viewType: TABLE,
        masterModule: CONFIGURATIONS_MANAGEMENT,
        subRoutes: [
          {
            path: '/configurations/sites-categories-mapping/create',
            title: 'Create Sites Categories Mapping',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: SITES_CATEGORIES_MAPPING,
            permissionRequired: CREATE,
            component: SitesCategoriesMappingCreate,
            viewType: GRID,
            masterModule: CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/configurations/sites-categories-mapping/update/:id',
            title: 'Update Sites Categories Mapping',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: SITES_CATEGORIES_MAPPING,
            permissionRequired: UPDATE,
            component: SitesCategoriesMappingUpdate,
            viewType: GRID,
            masterModule: CONFIGURATIONS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/configurations/common-apis',
        title: 'Common APIS',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: COMMON_APIS,
        permissionRequired: READ,
        component: CommonAPIS,
        viewType: GRID,
        masterModule: CONFIGURATIONS_MANAGEMENT,
      },
    ],
  },
  {
    path: '/offers-management',
    title: 'Offers Management',
    icon: faPercent,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: OFFERS_MANAGEMENT,
    permissionRequired: READ,
    isDummy: true,
    subRoutes: [
      {
        path: '/offers-management/offers',
        title: 'Offers',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: OFFERS,
        permissionRequired: READ,
        component: OffersTable,
        viewType: TABLE,
        masterModule: OFFERS_MANAGEMENT,
        subRoutes: [
          {
            path: '/offers-management/offers/create',
            title: 'Create Offer',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: OFFERS,
            permissionRequired: CREATE,
            component: OffersCreate,
            viewType: GRID,
            masterModule: OFFERS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/offers-management/offers/update/:id',
            title: 'Update Offer',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: OFFERS,
            permissionRequired: UPDATE,
            component: OffersUpdate,
            viewType: GRID,
            masterModule: OFFERS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
    ],
  },
];

export { ROUTES };
