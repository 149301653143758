import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk, faEdit, faKey } from "@fortawesome/free-solid-svg-icons";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../../components/NoData/NoData";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  PasswordInput,
  TextInput,
  CreateButton,
  ResetButton,
  CustomConfirmButton,
  MessageBox,
  Dropdown,
  Switch,
  CustomButton,
  Button,
} from "../../../components/FormElements";
import { Image } from "../../../components/Image";
import { TableBuilder } from "../../../components/TableElements";
import Modal from "../../../components/Modal";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import {
  StyledLink,
  StyledSpan,
  StyledModalContainer,
  StyledFiltersContainer,
  StyledOTPModal,
  StyledOTPText,
} from "../../../components/Styled";
import Pagination from "../../../components/Pagination/Pagination";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import {
  camelCaseToString,
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
} from "../../../utils";
import FiltersButton from "../../../components/FiltersButton/FiltersButton";
import { isEmpty } from "lodash";

const AccountsTable = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 30,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [accountsId, setAccountsId] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [accountsLatestOtp, setAccountsLatestOtp] = useState("");
  const [roles, setRoles] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [rolesId, setRolesId] = useState("");

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    setNewPassword("");
    setConfirmNewPassword("");
  };

  const resetFilters = () => {
    setSearchQuery("");
    setRolesId("");
    readData();
  };

  const readRoles = () => {
    API.get("/accounts-management/roles-and-permissions").then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setRoles(data);
      }
    });
  };

  const readData = useCallback(
    (searchQuery = "", rolesId = "") => {
      setIsLoading(true);
      setResponseStatus("");
      setResponseMessage("");
      setAccounts([]);
      API.get(
        `/accounts-management/accounts/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fRolesId=${rolesId}&searchQuery=${searchQuery}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
              redirectToPageOne();
            } else {
              setTotalRecords(pageInfo.totalRecords);
              setAccounts(data);
            }
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(searchQuery, rolesId);
  };

  const readAccountsLatestOTP = (accountsId) => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/accounts-management/accounts/last-otp/${accountsId}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setAccountsLatestOtp(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateData = (value, data) => {
    const newData = { ...data };
    newData.photo = "";
    newData.accountsStatus = value === true ? `Active` : `Inactive`;
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.put(
      `/accounts-management/accounts/${newData.id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fRolesId=${rolesId}&searchQuery=${searchQuery}`,
      newData
    )
      .then((response) => {
        const { status, message, data } = response.data;

        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            setAccounts(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteData = (id) => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.delete(
      `/accounts-management/accounts/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fRolesId=${rolesId}&searchQuery=${searchQuery}`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            setAccounts(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.put(`/accounts-management/accounts/change-password/${accountsId}`, {
      newPassword,
      confirmNewPassword,
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            setAccountsId("");
            setModalType("");
            setShowModal(false);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    readRoles();
  }, []);

  useEffect(() => {
    if (!isFiltering) {
      readData(searchQuery, rolesId);
    }
  }, [paginationData, readData]);

  const { accountsPermissions, subRoutes } = props;
  let updateLink = "";
  let updateTitle = "";
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canDelete =
    accountsPermissions.includes(PERMISSION_TYPES.DELETE) || false;
  const canChangePassword =
    accountsPermissions.includes(PERMISSION_TYPES.CHANGE_PASSWORD) || false;
  const canReadLatestOTP =
    accountsPermissions.includes(PERMISSION_TYPES.READ_LATEST_OTP) || false;
  if (canUpdate) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) => permissionRequired === PERMISSION_TYPES.UPDATE
    );
    updateLink = path;
    updateTitle = title;
  }

  const rolesOptions = [{ title: "-- SELECT ROLE --", value: "" }];
  roles.forEach(({ id, roleName }) =>
    rolesOptions.push({
      title: camelCaseToString(roleName),
      value: id,
    })
  );

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? "show" : ""}>
        <Form method="POST" action="#" onSubmit={filterData}>
          <Fieldset>
            <Label>Search</Label>
            <TextInput
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="First Name, Last Name, Email"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Role</Label>
            <Dropdown
              style={{ border: "1px solid #cacaca" }}
              placeholder="Role"
              value={rolesId}
              onChange={setRolesId}
              options={rolesOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type="submit">
              Filter
            </Button>
            <ResetButton
              disabled={isLoading}
              type="button"
              onClick={resetFilters}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === "" &&
          !showModal &&
          accounts?.length === 0 &&
          isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          accounts?.length === 0
        }
        message={`No accounts found`}
      />
      {!showModal && responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {accounts?.length > 0 && (
        <>
          <TableBuilder
            isLoading={!showModal && accounts?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: "",
                dataSelector: "id",
                align: "center",
                sticky: true,
                canSort: false,
                width: "100px",
                CellRenderer: (value, item) => (
                  <>
                    {canUpdate && (
                      <StyledLink
                        to={updateLink.replace(":id", value)}
                        title={updateTitle}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledLink>
                    )}
                    {canDelete && (
                      <CustomConfirmButton onClick={() => deleteData(value)} />
                    )}
                    {canChangePassword && (
                      <CustomButton
                        icon={faKey}
                        onClick={() => {
                          setAccountsId(value);
                          setModalType(PERMISSION_TYPES.CHANGE_PASSWORD);
                          setShowModal(true);
                        }}
                        style={{ color: "orange" }}
                      />
                    )}
                    {canReadLatestOTP && (
                      <CustomButton
                        icon={faAsterisk}
                        onClick={() => {
                          readAccountsLatestOTP(value);
                          setModalType(PERMISSION_TYPES.READ_LATEST_OTP);
                          setShowModal(true);
                        }}
                        style={{ color: "gray" }}
                      />
                    )}
                  </>
                ),
              },
              {
                title: "Photo",
                dataSelector: "photo",
                dataType: "string",
                canSort: false,
                CellRenderer: (value, item) => (
                  <Image
                    source={value ? value : "/no-image.png"}
                    alt={item.firstName}
                    style={{ width: "50px", height: "50px" }}
                  />
                ),
              },
              {
                title: "Full Name",
                dataSelector: "firstName",
                dataType: "string",
                CellRenderer: (value, item) => {
                  const { lastName, roleName } = item;
                  return (
                    <>
                      {value} {lastName}
                      <StyledSpan>{camelCaseToString(roleName)}</StyledSpan>
                    </>
                  );
                },
              },
              {
                title: "Email",
                dataSelector: "emailAddress",
                dataType: "string",
                CellRenderer: (value) => (!isEmpty(value) ? value : "N/A"),
              },
              {
                title: "Mobile",
                dataSelector: "mobileNumber",
                dataType: "number",
                CellRenderer: (value) =>
                  !isEmpty(value.toString()) ? value : "N/A",
              },
              {
                title: "Gender",
                dataSelector: "gender",
                dataType: "string",
                CellRenderer: (value) => (!isEmpty(value) ? value : "N/A"),
              },
              {
                title: "Date of Birth",
                dataSelector: "dateOfBirth",
                dataType: "string",
                CellRenderer: (value) => (!isEmpty(value) ? value : "N/A"),
              },
              {
                title: "Created On",
                dataSelector: "createdOn",
                dataType: "string",
                CellRenderer: (value) => (!isEmpty(value) ? value : "N/A"),
              },
              {
                title: "Status",
                dataSelector: "accountsStatus",
                dataType: "string",
                align: "center",
                canSort: false,
                CellRenderer: (value, data) => {
                  return (
                    <Switch
                      value={value === "Active"}
                      onChange={(value) => updateData(value, data)}
                    />
                  );
                },
              },
            ]}
            tableData={accounts}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
          <Modal
            modalStatus={showModal}
            setModalStatus={() => {
              resetResponseData();
              resetForm();
              setModalType("");
              setShowModal(false);
            }}
            hideCloseButton
          >
            <StyledModalContainer width={"500px"}>
              <h3>
                {modalType === PERMISSION_TYPES.CHANGE_PASSWORD
                  ? "Change Password"
                  : modalType === PERMISSION_TYPES.READ_LATEST_OTP
                  ? "Accounts Latest OTP"
                  : ""}
              </h3>
              <FormContainer>
                {modalType === PERMISSION_TYPES.READ_LATEST_OTP ? (
                  <StyledOTPModal>
                    {isLoading ? (
                      <StyledOTPText>
                        Please wait, we are feting the data
                      </StyledOTPText>
                    ) : accountsLatestOtp?.length > 0 ? (
                      <StyledOTPText>OTP is {accountsLatestOtp}</StyledOTPText>
                    ) : (
                      <StyledOTPText>No OTP Found</StyledOTPText>
                    )}
                    <OverlayLoader showLoader={showModal && isLoading} />
                  </StyledOTPModal>
                ) : (
                  <Form method="POST" action="#" onSubmit={onSubmit}>
                    {modalType === PERMISSION_TYPES.CHANGE_PASSWORD && (
                      <>
                        <Fieldset>
                          <Label required>New Password</Label>
                          <PasswordInput
                            placeholder="Please enter your new password"
                            value={newPassword}
                            onChange={setNewPassword}
                            autoComplete="off"
                            disabled={isLoading}
                          />
                        </Fieldset>
                        <Fieldset>
                          <Label required>Confirm New Password</Label>
                          <PasswordInput
                            placeholder="Please re-enter your new password"
                            value={confirmNewPassword}
                            onChange={setConfirmNewPassword}
                            autoComplete="off"
                            disabled={isLoading}
                          />
                        </Fieldset>
                      </>
                    )}
                    {showModal && responseStatus !== "" && (
                      <Fieldset>
                        <MessageBox
                          status={responseStatus}
                          message={responseMessage}
                        />
                      </Fieldset>
                    )}
                    <Fieldset>
                      <CreateButton
                        disabled={isLoading}
                        type="submit"
                        style={{ width: "auto" }}
                      >
                        {modalType === PERMISSION_TYPES.CHANGE_PASSWORD
                          ? `Change Password`
                          : `Submit`}
                      </CreateButton>
                      <ResetButton
                        disabled={isLoading}
                        type="button"
                        onClick={resetForm}
                      >
                        Reset
                      </ResetButton>
                    </Fieldset>
                    <OverlayLoader showLoader={showModal && isLoading} />
                  </Form>
                )}
              </FormContainer>
            </StyledModalContainer>
          </Modal>
        </>
      )}
    </>
  );
};

export default withAppHOC(AccountsTable);
