import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Logo from '../components/Logo/Logo';
import Header from '../components/Header/Header';
import Navigation from '../components/Navigation/Navigation';
import Footer from '../components/Footer/Footer';
import API from '../api';
import { authData } from '../utils';
import { API_RESPONSE_TYPES } from '../constants';

const StyledMain = styled.main`
  width: 95%;
  max-width: 1800px;
  height: auto;
  margin: 0 auto;
  padding: 0px;
`;

const StyledAuthMainOuter = styled.section`
  width: 100%;
  height: 90%;
  height: auto;
  background-color: #ffffff;
`;

const StyledMainSection = styled.section`
  width: 95%;
  max-width: 1800px;
  height: auto;
  margin: 0 auto;
  padding: 50px 0px;
  @media (max-width: 1000px) {
    width: 100%;
    display: block;
    padding: 10px 0px;
  }
`;

const StyledAuthHeader = styled.section`
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  @media (max-width: 1000px) {
    margin-bottom: 10px;
  }
`;

const StyledAuthFormSection = styled.section`
  width: 500px;
  height: auto;
  margin: 0px auto;
  padding: 0px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const withAuthHOC = (WrappedComponent) => {
  return (props) => {
    const { appName, appLogo } = props.appData;

    return (
      <>
        <StyledAuthMainOuter>
          <StyledMainSection>
            <StyledAuthFormSection>
              <StyledAuthHeader>
                <Logo src={appLogo} alt={appName} type='auth' />
              </StyledAuthHeader>
              <WrappedComponent {...props} />
            </StyledAuthFormSection>
          </StyledMainSection>
        </StyledAuthMainOuter>
        <Footer appName={appName} />
      </>
    );
  };
};

const withAppHOC = (WrappedComponent) => {
  return (props) => {
    const { appName, appLogo, appPhoneNumber, appEmailAddress } = props.appData;
    const [showNav, setShowNav] = useState(false);
    const [profileName, setProfileName] = useState('-');
    const [profilePhoto, setProfilePhoto] = useState('');

    const onClickLogout = (e) => {
      e.preventDefault();
      const { authToken } = authData.get();
      API.delete(`/auth/sessions/?sessionToken=${authToken}`);
      authData.clear();
      window.location.href = '/login';
    };

    const readProfile = useCallback(() => {
      API.get(`/auth/profile`).then((response) => {
        const { status, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setProfileName(`${data.firstName} ${data.lastName}` || 'N/A');
          setProfilePhoto(data.photo || '');
          authData.set('accountsPermissions', data.permissions);
          authData.set('accountData', JSON.stringify(data));
        }
      });
    }, []);

    const onClickNavigationIcon = () => {
      setShowNav((showNav) => !showNav);
    };

    useEffect(() => {
      readProfile();
    }, [readProfile]);

    return (
      <>
        <Header
          supportMobileNumber={appPhoneNumber}
          supportEmailAddress={appEmailAddress}
          fullName={profileName}
          profilePhoto={profilePhoto}
          onClickLogout={onClickLogout}
          appLogo={appLogo}
          appName={appName}
          showNav={showNav}
          setShowNav={onClickNavigationIcon}
        />
        <Navigation showNav={showNav} />
        <StyledMain>
          <WrappedComponent {...props} />
        </StyledMain>
        <Footer appName={appName} />
      </>
    );
  };
};

export { withAuthHOC, withAppHOC };
