import React, { useState, useEffect } from "react";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  MessageBox,
  CreateButton,
  ResetButton,
  Dropdown,
  FileInput,
} from "../../../components/FormElements";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import { getBase64, getReturnUrl } from "../../../utils";

const SitesCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [siteTitle, setSiteTitle] = useState("");
  const [domain, setDomain] = useState("");
  const [favicon, setFavicon] = useState([]);
  const [faviconBase64, setFaviconBase64] = useState("");
  const [siteStatus, setSiteStatus] = useState("");

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    setSiteTitle("");
    setDomain("");
    setFavicon([]);
    setFaviconBase64("");
    setSiteStatus("");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      siteTitle,
      domain,
      favicon: faviconBase64,
      siteStatus,
    };
    API.post("/configurations/sites", formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (favicon?.length > 0) {
      getBase64(favicon[0], setFaviconBase64);
    }
  }, [favicon]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Fieldset>
            <Label required>Site Title</Label>
            <TextInput
              value={siteTitle}
              onChange={setSiteTitle}
              placeholder="Please enter site title"
              maxLength={100}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label required>Domain</Label>
            <TextInput
              value={domain}
              onChange={setDomain}
              placeholder="Please enter domain name"
              maxLength={10000}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Site Favicon</Label>
            <FileInput
              files={favicon}
              onChange={setFavicon}
              accept="image/*"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label required>Site Status</Label>
            <Dropdown
              placeholder="Please select site status"
              value={siteStatus}
              onChange={setSiteStatus}
              options={[
                {
                  title: "-- SELECT SITE STATUS --",
                  value: "",
                },
                {
                  title: "Active",
                  value: "Active",
                },
                {
                  title: "Inactive",
                  value: "Inactive",
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading} type="submit">
              Create
            </CreateButton>
            <ResetButton disabled={isLoading} type="button" onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(SitesCreate);
