import React, { useState, useEffect } from "react";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import { Grid, GridItem } from "../../../components/Grid";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  NumberInput,
  Dropdown,
  FileInput,
  MessageBox,
  CreateButton,
  ResetButton,
  PasswordInput,
} from "../../../components/FormElements";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import { getBase64, getReturnUrl } from "../../../utils";

const UsersCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [photo, setPhoto] = useState([]);
  const [photoBase64, setPhotoBase64] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [usersStatus, setUsersStatus] = useState("");
  const [emailVerificationStatus, setEmailVerificationStatus] = useState("");
  const [mobileVerificationStatus, setMobileVerificationStatus] = useState("");

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setMobileNumber("");
    setGender("");
    setDateOfBirth("");
    setPhoto([]);
    setPhotoBase64("");
    setAddress("");
    setPassword("");
    setConfirmPassword("");
    setUsersStatus("");
    setEmailVerificationStatus("");
    setMobileVerificationStatus("");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      firstName: firstName,
      lastName: lastName,
      emailAddress: emailAddress,
      mobileNumber: mobileNumber,
      gender: gender,
      dateOfBirth: dateOfBirth,
      photo: photoBase64,
      address: address,
      password: password,
      confirmPassword: confirmPassword,
      usersStatus: usersStatus,
      emailVerificationStatus: emailVerificationStatus,
      mobileVerificationStatus: mobileVerificationStatus,
    };

    API.post("/accounts-management/users", formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (photo?.length > 0) {
      getBase64(photo[0], setPhotoBase64);
    }
  }, [photo]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset>
                <Label required>First Name</Label>
                <TextInput
                  value={firstName}
                  onChange={setFirstName}
                  placeholder="Please enter first name"
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Last Name</Label>
                <TextInput
                  value={lastName}
                  onChange={setLastName}
                  placeholder="Please enter last name"
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset>
                <Label required>Email Address</Label>
                <TextInput
                  value={emailAddress}
                  onChange={setEmailAddress}
                  placeholder="Please enter email address"
                  maxLength={200}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Email Verification Status</Label>
                <Dropdown
                  placeholder="Please select email verification status"
                  value={emailVerificationStatus}
                  onChange={setEmailVerificationStatus}
                  options={[
                    {
                      title: "-- SELECT EMAIL VERIFICATION STATUS --",
                      value: "",
                    },
                    {
                      title: "Pending",
                      value: "Pending",
                    },
                    {
                      title: "Verified",
                      value: "Verified",
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset>
                <Label>Mobile Number</Label>
                <NumberInput
                  value={mobileNumber}
                  onChange={setMobileNumber}
                  placeholder="Please enter mobile number"
                  maxLength={10}
                  disabled={isLoading}
                  pattern={`[0-9]*`}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Mobile Verification Status</Label>
                <Dropdown
                  placeholder="Please select mobile verification status"
                  value={mobileVerificationStatus}
                  onChange={setMobileVerificationStatus}
                  options={[
                    {
                      title: "-- SELECT MOBILE VERIFICATION STATUS --",
                      value: "",
                    },
                    {
                      title: "Pending",
                      value: "Pending",
                    },
                    {
                      title: "Verified",
                      value: "Verified",
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset>
                <Label>Gender</Label>
                <Dropdown
                  placeholder="Please select gender"
                  value={gender}
                  onChange={setGender}
                  options={[
                    {
                      title: "-- SELECT GENDER --",
                      value: "",
                    },
                    {
                      title: "Male",
                      value: "Male",
                    },
                    {
                      title: "Female",
                      value: "Female",
                    },
                    {
                      title: "Others",
                      value: "Others",
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Date Of Birth</Label>
                <TextInput
                  type="date"
                  value={dateOfBirth}
                  onChange={setDateOfBirth}
                  placeholder="Please select date of birth"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Photo</Label>
            <FileInput
              files={photo}
              onChange={setPhoto}
              accept="image/*"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Address</Label>
            <TextInput
              value={address}
              onChange={setAddress}
              placeholder="Please enter address"
              disabled={isLoading}
            />
          </Fieldset>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset>
                <Label required>Password</Label>
                <PasswordInput
                  value={password}
                  onChange={setPassword}
                  placeholder="Please enter password"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Confirm Password</Label>
                <PasswordInput
                  value={confirmPassword}
                  onChange={setConfirmPassword}
                  placeholder="Please re-enter password"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label required>Account Status</Label>
            <Dropdown
              placeholder="Please select aadhaar verification status"
              value={usersStatus}
              onChange={setUsersStatus}
              options={[
                {
                  title: "-- SELECT ACCOUNTS STATUS --",
                  value: "",
                },
                {
                  title: "Active",
                  value: "Active",
                },
                {
                  title: "Inactive",
                  value: "Inactive",
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading} type="submit">
              Create
            </CreateButton>
            <ResetButton disabled={isLoading} type="button" onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(UsersCreate);
