import React from 'react';
import styled from 'styled-components';
import { Image } from '../Image';

const StyledButton = styled.button`
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
  background-color: #00a4da;
  border: 0px;
  border-radius: 50px;
  margin: 0px;
  padding: ${({ isLoading }) => (isLoading ? `5px 0px` : `12px 30px`)};
  letter-spacing: 0px;
  outline: 0px;
  cursor: pointer;
  width: ${({ isLoading }) => (isLoading ? `100px` : `auto`)};
  height: ${({ isLoading }) => (isLoading ? `48px` : `auto`)};
  transition: background-color 0.5s, color 0.5s;
  &:hover {
    background-color: #006ab2;
    color: #ffffff;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #d3d3d3;
    color: #989898;
    &:hover {
      cursor: not-allowed;
      background-color: #d3d3d3;
      color: #989898;
    }
  }
  img {
    width: 25px;
    height: auto;
  }
`;

const Button = ({ isLoading = false, children, ...rest }) => {
  return (
    <StyledButton isLoading={isLoading} {...rest}>
      {isLoading ? <Image source={'/loading.gif'} alt='Loading' /> : children}
    </StyledButton>
  );
};

export default Button;
