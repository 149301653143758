const { protocol, host } = window.location;

const APIConfig = {
  API_BASE_URL:
    process.env.NODE_ENV === 'development'
      ? `http://localhost:4001/v1`
      : `${protocol}//apis.${host.replace('www.', '')}/v1`,
  HEADERS: {
    POST: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    GET: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    PUT: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    DELETE: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  },
};

export default APIConfig;
