import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import {
  Form,
  Fieldset,
  Label,
  TextInput,
  ResetButton,
  MessageBox,
  Button,
} from '../../../components/FormElements';
import { TableBuilder } from '../../../components/TableElements';
import { StyledFiltersContainer } from '../../../components/Styled';
import Pagination from '../../../components/Pagination/Pagination';
import API from '../../../api';
import { API_RESPONSE_TYPES } from '../../../constants';
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
} from '../../../utils';
import FiltersButton from '../../../components/FiltersButton/FiltersButton';
import { isEmpty } from 'lodash';

const UsersBrowsersHistory = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 30,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [history, setHistory] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { id } = useParams();

  const resetFilters = () => {
    setSearchQuery('');
    readData();
  };

  const readData = useCallback(
    (searchQuery = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      setHistory([]);
      API.get(
        `/accounts-management/users/browser-history/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&searchQuery=${searchQuery}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
              redirectToPageOne();
            } else {
              setTotalRecords(pageInfo.totalRecords);
              setHistory(data);
            }
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData, id]
  );

  const reloadData = () => {
    readData(searchQuery);
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(searchQuery);
    }
  }, [paginationData, readData]);

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method='POST' action='#' onSubmit={filterData}>
          <Fieldset>
            <Label>Search</Label>
            <TextInput
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder='Search...'
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type='submit'>
              Filter
            </Button>
            <ResetButton
              disabled={isLoading}
              type='button'
              onClick={resetFilters}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={responseStatus === '' && history?.length === 0 && isLoading}
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          history?.length === 0
        }
        message={`No history found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {history?.length > 0 && (
        <>
          <TableBuilder
            isLoading={history?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: 'ID',
                dataSelector: 'id',
                align: 'center',
                sticky: true,
                canSort: false,
                width: '100px',
                CellRenderer: (value) => <>{value}</>,
              },
              {
                title: 'URL',
                dataSelector: 'url',
                dataType: 'string',
                CellRenderer: (value) => (!isEmpty(value) ? value : 'N/A'),
              },
              {
                title: 'Created On',
                dataSelector: 'createdOn',
                dataType: 'string',
                CellRenderer: (value) => (!isEmpty(value) ? value : 'N/A'),
              },
            ]}
            tableData={history}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(UsersBrowsersHistory);
