import React, { useState, useEffect } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  CreateButton,
  ResetButton,
} from '../../../components/FormElements';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getReturnUrl } from '../../../utils';
import { useCallback } from 'react';

const SitesCategoriesMappingCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [sites, setSites] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sitesId, setSitesId] = useState('');
  const [categoriesId, setCategoriesId] = useState('');
  const [categoryKey, setCategoryKey] = useState('');
  const [categoryValue, setCategoryValue] = useState('');
  const [mappingStatus, setMappingStatus] = useState('');

  const readSites = useCallback(() => {
    API.get('/configurations/sites').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setSites(data);
      }
    });
  }, []);

  const readCategories = useCallback(() => {
    API.get('/configurations/categories').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setCategories(data);
      }
    });
  }, []);

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setSitesId('');
    setCategoriesId('');
    setCategoryKey('');
    setCategoryValue('');
    setMappingStatus('');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      sitesId: sitesId,
      categoriesId: categoriesId,
      categoryKey: categoryKey,
      categoryValue: categoryValue,
      mappingStatus: mappingStatus,
    };
    API.post('/configurations/sites-categories-mapping', formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readSites();
    readCategories();
  }, [readSites, readCategories]);

  const sitesOptions = [{ title: '-- SELECT SITE --', value: '' }];
  if (sites.length > 0) {
    sites?.forEach(({ id, siteTitle }) =>
      sitesOptions.push({
        title: siteTitle,
        value: id,
      })
    );
  }

  const categoriesOptions = [{ title: '-- SELECT CATEGORY --', value: '' }];
  if (categories.length > 0) {
    categories?.forEach(({ id, category }) =>
      categoriesOptions.push({
        title: category,
        value: id,
      })
    );
  }

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Site</Label>
                <Dropdown
                  placeholder='Please select site'
                  value={sitesId}
                  onChange={setSitesId}
                  options={sitesOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Category</Label>
                <Dropdown
                  placeholder='Please select category'
                  value={categoriesId}
                  onChange={setCategoriesId}
                  options={categoriesOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Category Key</Label>
                <TextInput
                  value={categoryKey}
                  onChange={setCategoryKey}
                  placeholder='Please enter category key'
                  maxLength={1000}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Category Value</Label>
                <TextInput
                  value={categoryValue}
                  onChange={setCategoryValue}
                  placeholder='Please enter category value'
                  maxLength={1000}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label required>Mapping Status</Label>
            <Dropdown
              placeholder='Please select mapping status'
              value={mappingStatus}
              onChange={setMappingStatus}
              options={[
                {
                  title: '-- SELECT MAPPING STATUS --',
                  value: '',
                },
                {
                  title: 'Active',
                  value: 'Active',
                },
                {
                  title: 'Inactive',
                  value: 'Inactive',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading} type='submit'>
              Create
            </CreateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(SitesCategoriesMappingCreate);
