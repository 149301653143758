import React, { useState } from "react";
import { withAppHOC } from "../../hoc";
import PageHeading from "../../components/PageHeader/PageHeader";
import OverlayLoader from "../../components/OverlayLoader/OverlayLoader";
import { Grid, GridItem } from "../../components/Grid";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  PasswordInput,
  MessageBox,
  Button,
} from "../../components/FormElements";
import API from "../../api";
import { API_RESPONSE_TYPES } from "../../constants";

const ChangePassword = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    setNewPassword("");
    setConfirmNewPassword("");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      newPassword,
      confirmNewPassword,
    };
    API.post("/auth/change-password", formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Grid
            columns={
              "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>New Password</Label>
                <PasswordInput
                  placeholder="Please enter your new password"
                  value={newPassword}
                  onChange={setNewPassword}
                  autoComplete="off"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Confirm New Password</Label>
                <PasswordInput
                  placeholder="Please re-enter your new password"
                  value={confirmNewPassword}
                  onChange={setConfirmNewPassword}
                  autoComplete="off"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Grid
              columns={
                "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
              }
            >
              <GridItem>
                <Fieldset>
                  <MessageBox
                    status={responseStatus}
                    message={responseMessage}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          )}
          <Fieldset>
            <Button disabled={isLoading} type="submit">
              Change Password
            </Button>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(ChangePassword);
