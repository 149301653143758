import isEmpty from "lodash/isEmpty";
import { ROUTE_TYPES, PERMISSION_TYPES } from "../constants";
import { ROUTES } from "../constants/routes.config";

const getPlatform = () => {
  return "Web";
};

const getDeviceType = () => {
  return navigator?.userAgentData?.mobile ? "Mobile" : "Desktop";
};

const getOperatingSystem = () => {
  return navigator?.userAgentData?.platform ?? "Unknown";
};

const shouldRedirect = (dataLength = 0, pageNumber = 1) => {
  return dataLength === 0 && parseInt(pageNumber) !== 1 ? true : false;
};

const redirectToPageOne = () => {
  window.location.href = window.location.href.split("?")?.[0];
};

const authData = {
  get: () => {
    return {
      isLoggedIn: window.localStorage.getItem("isLoggedIn") === "true" || false,
      authId: window.localStorage.getItem("authId") || null,
      authToken: window.localStorage.getItem("authToken") || null,
      accountsPermissions:
        JSON.parse(window.localStorage.getItem("accountsPermissions")) || null,
      accountData:
        JSON.parse(window.localStorage.getItem("accountData")) || null,
    };
  },
  set: (key, value) => window.localStorage.setItem(key, value),
  clear: () => window.localStorage.clear(),
};

const checkRoutePermission = ({
  routeType,
  moduleName,
  permissionRequired,
}) => {
  const { ALLOW_IF_LOGGEDIN, ALLOW_IF_NOT_LOGGEDIN } = ROUTE_TYPES;
  const { isLoggedIn, accountsPermissions } = authData.get();
  const routeTypeCheck =
    isEmpty(routeType) || (routeType === ALLOW_IF_LOGGEDIN && isLoggedIn);
  const modulePermissionCheck =
    (routeType === ALLOW_IF_NOT_LOGGEDIN && isEmpty(moduleName)) ||
    (routeType === ALLOW_IF_LOGGEDIN &&
      !isEmpty(moduleName) &&
      !isEmpty(permissionRequired) &&
      (!isEmpty(accountsPermissions[moduleName])
        ? accountsPermissions[moduleName].includes(permissionRequired)
        : false));

  return routeTypeCheck && modulePermissionCheck;
};

const generateRoutes = () => {
  const routesList = [];

  (function loopRoutes(routes) {
    !isEmpty(routes) &&
      routes.forEach((route) => {
        route.isDummy !== true && route.path && routesList.push(route);
        if (!isEmpty(route.subRoutes)) {
          loopRoutes(route.subRoutes);
        }
      });
  })(ROUTES);

  return routesList;
};

const validators = {
  isNumber: (event) => {
    event = event ? event : window.event;
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  },
};

const getBase64 = (file, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    cb(reader.result);
  };
};

const camelCaseToString = (text) => {
  const result = text?.replace(/([A-Z])/g, " $1");
  return result?.charAt(0)?.toUpperCase() + result?.slice(1) || "";
};

const getSearchParams = (key) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(key);
};

const getReturnUrl = (type) => {
  let returnUrl = "/";
  const href = window.location.href;

  if (type === PERMISSION_TYPES.CREATE) {
    const exp = href.split("/create");
    returnUrl = exp[0];
  } else if (type === PERMISSION_TYPES.UPDATE) {
    const exp = href.split("/update");
    returnUrl = exp[0];
  }

  return returnUrl;
};

export {
  getPlatform,
  getDeviceType,
  getOperatingSystem,
  shouldRedirect,
  redirectToPageOne,
  authData,
  checkRoutePermission,
  generateRoutes,
  validators,
  getBase64,
  camelCaseToString,
  getSearchParams,
  getReturnUrl,
};
