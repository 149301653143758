import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import Modal from "../../../components/Modal";
import { Image } from "../../../components/Image";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  FileInput,
  MessageBox,
  UpdateButton,
  ResetButton,
} from "../../../components/FormElements";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import { getBase64, getReturnUrl } from "../../../utils";

const StyledViewAttachmentButton = styled.span`
  font-size: 14px;
  width: calc(100% - 22px);
  height: auto;
  border: 1px solid #d0eeff;
  display: block;
  margin: 20px 0px 0px;
  background-color: #edf8ff;
  padding: 15px 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s;
  position: relative;
  &:hover {
    background-color: #d0eeff;
  }
`;

const ProvidersUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showExistingImage, setShowExistingImage] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [providerName, setProviderName] = useState("");
  const [providerLogo, setProviderLogo] = useState([]);
  const [providerLogoBase64, setProviderLogoBase64] = useState("");
  const [providerStatus, setProviderStatus] = useState("");
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    const { providerName, providerStatus } = originalFormData;
    setProviderName(providerName);
    setProviderLogo([]);
    setProviderLogoBase64("");
    setProviderStatus(providerStatus);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/configurations/providers/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const { providerName, providerLogo, providerStatus } = data[0];
          setProviderName(providerName);
          setProviderLogo([]);
          setProviderLogoBase64("");
          setProviderStatus(providerStatus);
          setOriginalFormData({
            providerName,
            providerLogo,
            providerStatus,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      providerName,
      providerLogo: providerLogoBase64,
      providerStatus,
    };
    API.put(`/configurations/providers/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (providerLogo?.length > 0) {
      getBase64(providerLogo[0], setProviderLogoBase64);
    }
  }, [providerLogo]);

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Fieldset>
            <Label required>Provider Name</Label>
            <TextInput
              value={providerName}
              onChange={setProviderName}
              placeholder="Please enter provider name"
              maxLength={100}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Provider Logo</Label>
            <FileInput
              files={providerLogo}
              onChange={setProviderLogo}
              accept="image/*"
              disabled={isLoading}
            />
            {originalFormData?.providerLogo && (
              <StyledViewAttachmentButton
                onClick={() => {
                  setShowExistingImage(true);
                  setModalImage(originalFormData.providerLogo);
                }}
              >
                Click here to view existing file
              </StyledViewAttachmentButton>
            )}
          </Fieldset>
          <Fieldset>
            <Label required>Provider Status</Label>
            <Dropdown
              placeholder="Please select provider status"
              value={providerStatus}
              onChange={setProviderStatus}
              options={[
                {
                  title: "-- SELECT PROVIDER STATUS --",
                  value: "",
                },
                {
                  title: "Active",
                  value: "Active",
                },
                {
                  title: "Inactive",
                  value: "Inactive",
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type="submit">
              Update
            </UpdateButton>
            <ResetButton disabled={isLoading} type="button" onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
        <Modal
          modalStatus={showExistingImage}
          setModalStatus={setShowExistingImage}
          hideCloseButton
        >
          <Image width={150} source={modalImage} alt={"Existing File"} />
        </Modal>
      </FormContainer>
    </>
  );
};

export default withAppHOC(ProvidersUpdate);
