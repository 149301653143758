import React, { useState, useEffect } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  CreateButton,
  ResetButton,
  Textarea,
} from '../../../components/FormElements';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getReturnUrl } from '../../../utils';
import { useCallback } from 'react';

const OffersCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [sites, setSites] = useState([]);
  const [sitesId, setSitesId] = useState('');
  const [banks, setBanks] = useState([]);
  const [banksId, setBanksId] = useState('');
  const [providers, setProviders] = useState([]);
  const [providersId, setProvidersId] = useState('');
  const [cards, setCards] = useState([]);
  const [cardsId, setCardsId] = useState('');
  const [categories, setCategories] = useState([]);
  const [categoriesId, setCategoriesId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [discountType, setDiscountType] = useState('');
  const [discountValue, setDiscountValue] = useState('');

  const readSites = useCallback(() => {
    API.get('/configurations/sites').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setSites(data);
      }
    });
  }, []);

  const readBanks = useCallback(() => {
    API.get('/configurations/banks').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setBanks(data);
      }
    });
  }, []);

  const readProviders = useCallback(() => {
    API.get('/configurations/providers').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setProviders(data);
      }
    });
  }, []);

  const readCards = useCallback(() => {
    API.get('/configurations/cards').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setCards(data);
      }
    });
  }, []);

  const readCategories = useCallback(() => {
    API.get('/configurations/categories').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setCategories(data);
      }
    });
  }, []);

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setSitesId('');
    setBanksId('');
    setProvidersId('');
    setCardsId('');
    setCategoriesId('');
    setStartDate('');
    setEndDate('');
    setShortDescription('');
    setLongDescription('');
    setTermsAndConditions('');
    setDiscountType('');
    setDiscountValue('');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      sitesId,
      banksId,
      providersId,
      cardsId,
      categoriesId,
      startDate,
      endDate,
      shortDescription,
      longDescription,
      termsAndConditions,
      discountType,
      discountValue,
    };
    API.post('/offers', formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readSites();
    readBanks();
    readProviders();
    readCards();
    readCategories();
  }, [readSites, readBanks, readProviders, readCards, readCategories]);

  const sitesOptions = [{ title: '-- SELECT SITE --', value: '' }];
  if (sites.length > 0) {
    sites?.forEach(({ id, siteTitle }) =>
      sitesOptions.push({
        title: siteTitle,
        value: id,
      })
    );
  }

  const banksOptions = [{ title: '-- SELECT BANK --', value: '' }];
  if (banks.length > 0) {
    banks?.forEach(({ id, bankName }) =>
      banksOptions.push({
        title: bankName,
        value: id,
      })
    );
  }

  const providersOptions = [{ title: '-- SELECT PROVIDER --', value: '' }];
  if (providers.length > 0) {
    providers?.forEach(({ id, providerName }) =>
      providersOptions.push({
        title: providerName,
        value: id,
      })
    );
  }

  let cardsOptions = [{ title: '-- SELECT CARD --', value: '' }];
  if (cards.length > 0) {
    cards?.forEach((item) =>
      cardsOptions.push({
        title: item?.cardTitle,
        value: item?.id,
        banksId: item?.banksId,
        providersId: item?.providersId,
      })
    );
  }

  const categoriesOptions = [{ title: '-- SELECT CATEGORY --', value: '' }];
  if (categories.length > 0) {
    categories?.forEach(({ id, category }) =>
      categoriesOptions.push({
        title: category,
        value: id,
      })
    );
  }

  if (banksId) {
    cardsOptions = cardsOptions.filter((item) =>
      banksId ? item.banksId === banksId : true
    );
  }

  if (providersId) {
    cardsOptions = cardsOptions.filter((item) =>
      providersId ? item.providersId === providersId : true
    );
  }

  const discountTypeOptions = [
    { title: '-- SELECT DISCOUNT TYPE --', value: '' },
    { title: 'Flat', value: 'Flat' },
    { title: 'Percentage', value: 'Percentage' },
  ];

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Grid
            columns={
              'calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Site</Label>
                <Dropdown
                  placeholder='Please select site'
                  value={sitesId}
                  onChange={setSitesId}
                  options={sitesOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Bank</Label>
                <Dropdown
                  placeholder='Please select bank'
                  value={banksId}
                  onChange={(value) => {
                    setProvidersId('');
                    setCardsId('');
                    setBanksId(value);
                  }}
                  options={banksOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Provider</Label>
                <Dropdown
                  placeholder='Please select provider'
                  value={providersId}
                  onChange={(value) => {
                    setCardsId('');
                    setProvidersId(value);
                  }}
                  options={providersOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Card</Label>
                <Dropdown
                  placeholder='Please select card'
                  value={cardsId}
                  onChange={(value) => {
                    const item = cards?.find((item) => item.id === value);
                    setBanksId(item?.banksId || '');
                    setProvidersId(item?.providersId || '');
                    setCardsId(value);
                  }}
                  options={cardsOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label>Start Date</Label>
                <TextInput
                  type='date'
                  value={startDate}
                  onChange={setStartDate}
                  placeholder='Start Date'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>End Date</Label>
                <TextInput
                  type='date'
                  value={endDate}
                  onChange={setEndDate}
                  placeholder='End Date'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Discount Type</Label>
                <Dropdown
                  placeholder='Please select discount type'
                  value={discountType}
                  onChange={setDiscountType}
                  options={discountTypeOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Discount Value</Label>
                <TextInput
                  value={discountValue}
                  onChange={setDiscountValue}
                  placeholder='Please enter discount value'
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Category</Label>
            <Dropdown
              placeholder='Please select category'
              value={categoriesId}
              onChange={setCategoriesId}
              options={categoriesOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label required>Short Description</Label>
            <Textarea
              placeholder='Please enter short description'
              value={shortDescription}
              onChange={(e) => {
                setShortDescription(e.target.value);
              }}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Long Description</Label>
            <Textarea
              placeholder='Please enter long description'
              value={longDescription}
              onChange={(e) => {
                setLongDescription(e.target.value);
              }}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Terms & Conditions</Label>
            <Textarea
              placeholder='Please enter terms and condtions'
              value={termsAndConditions}
              onChange={(e) => {
                setTermsAndConditions(e.target.value);
              }}
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading} type='submit'>
              Create
            </CreateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(OffersCreate);
